import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import messages from './messages';
import connection from './connection';
import chat from './chat';
import volunteer from './volunteer';
import admin from './admin';
import app from './app';
import settings from './settings';

const rootReducer = (history: History) =>
    combineReducers({
        chat,
        connection,
        messages,
        volunteer,
        admin,
        app,
        router: connectRouter(history),
        settings,
    });

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export default rootReducer;
