import styled from 'styled-components';
import StyledMessage from 'components/Message/styled';

const StyledLocalMessage = styled(StyledMessage)`
    background-color: ${(props) => props.theme.colors.lightGreen};
    align-items: flex-end;
    margin-left: auto;

    /* Avatar */
    svg {
        position: absolute;
        right: -40px;
        left: auto;
        top: 2px;
    }

    /* Speech bubble arrow */
    &::after {
        background-color: ${(props) => props.theme.colors.lightGreen};
        left: auto;
        right: -5px;
    }

    &.sending {
        opacity: 0.5;
    }

    &.failed {
        background-color: ${(props) => props.theme.colors.lightPink};
        align-items: flex-end;
        margin-left: auto;

        /* Speech bubble arrow */
        &::after {
            background-color: ${(props) => props.theme.colors.lightPink};
        }
    }
`;

export default StyledLocalMessage;
