import {
    Room,
    setLandingRoom,
    setWaitingRoom,
    setFeedbackRoom,
    setChatRoom,
    setRoom,
    setStarted,
    setQueued,
} from 'reducers/chat';
import { Status } from 'reducers/chat';
import { AppMiddleware } from 'middleware';
import { changeTile } from 'reducers/actions';

var currentRoom: Room | null = null;
var currentTile = '';

const hotJar: AppMiddleware = (store) => (next) => async (action) => {
    const hj = (window as any).hj || console.log;

    if (!hj) {
        return next(action);
    }
    // process the action so the state is updated
    const result = await next(action);

    const state = store.getState();
    const { type: actionType } = action;
    if (state.chat.status !== Status.LOADING) {
        switch (actionType) {
            case setLandingRoom.toString():
            case setWaitingRoom.toString():
            case setChatRoom.toString():
            case setFeedbackRoom.toString():
            case setRoom.toString():
                if (currentRoom !== state.chat.room) {
                    hj('stateChange', `${state.chat.room.toLowerCase()}/`);
                    currentRoom = state.chat.room;
                }
                break;
            case setStarted.toString():
                if (state.chat.room !== Room.CHAT) {
                    hj(
                        'stateChange',
                        `${Room.WAITING.toLowerCase()}/ready-to-start/`,
                    );
                }
                break;
            case changeTile.toString():
                if (currentTile !== action.tile) {
                    hj(
                        'stateChange',
                        `${state.chat.room.toLowerCase()}/${action.tile}/`,
                    );
                    currentTile = action.tile;
                }
                break;
            default:
                break;
        }
    } else {
        switch (action.type) {
            case setQueued.toString():
                hj('stateChange', `${Room.WAITING.toLowerCase()}/`);
                currentRoom = Room.WAITING;
                break;
            case setStarted.toString():
                hj('stateChange', `${Room.CHAT.toLowerCase()}/`);
                break;
            default:
                break;
        }
    }
    return result;
};

export default hotJar;
