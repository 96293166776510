import store from 'store';
import { CHAT_API_URL } from 'chatConstants';

export type ErrorState = {
    detail: string;
    password: string;
    username: string;
};

type Token = {
    access: string;
    refresh: string;
};

type LoginData = {
    username: string;
    password: string;
};

export const isToken = (token: any): token is Token => {
    return (
        !!token &&
        (token as Token).access !== undefined &&
        (token as Token).refresh !== undefined
    );
};

export const processLogin = async (data: LoginData) => {
    let response = await fetch(CHAT_API_URL + '/token/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    let json = await response.json();
    if (json.access && json.refresh) {
        setToken(json);
        return json as Token;
    }
    return json as ErrorState;
};

export const processRefresh = async () => {
    const token = getToken();
    if (!token) {
        await logout();
        return null;
    }
    let response = await fetch(CHAT_API_URL + '/token/refresh/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: token.refresh }),
    });
    if (response.status === 401 || response.status === 400) {
        await logout();
        return null;
    } else {
        let json = (await response.json()) as Token;
        const newToken = {
            ...getToken(),
            ...json,
        };
        setToken(newToken);
        return newToken;
    }
};

const tokenStore = 'token';

export const getToken = () => store.get(tokenStore) as undefined | Token;

export const setToken = (token: Token) => {
    store.set(tokenStore, token);
};

export const logout = async () => {
    store.remove(tokenStore);
};
