import styled from 'styled-components';
import device from 'styles/device';

const StyledMessageInput = styled.div`
    background-color: ${(props) => props.theme.colors.offWhite};
    padding: 5px;
    z-index: 5;

    @media ${device.tabletPortrait} {
        padding: 10px 10px;
    }

    div {
        background-color: ${(props) => props.theme.colors.white};
        border-radius: 3px;
        position: relative;
        border: 1px solid ${(props) => props.theme.colors.lightGrey};
        padding: 4px;
    }

    textarea {
        width: 75%;
        border: 0;
        padding: 5px;
        font-size: inherit;
        height: 34px;
        resize: none;
        overflow-y: scroll;
        max-height: 90px;
        vertical-align: middle;
        display: inline-block;
        background-color: inherit;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        /* landscape rules - orientation rule will not work due to mobile soft-keyboards */
        @media screen and (min-aspect-ratio: 13/9) and (max-width: 800px) {
            max-height: 45px;
        }

        @media ${device.tabletPortrait} {
            height: 44px;
            padding: 10px;
        }

        @media ${device.mobLandscape} {
            width: 85%;
        }
    }

    button {
        appearance: none;
        background-color: ${(props) => props.theme.colors.secondary};
        border: 0;
        border-radius: 3px;
        float: right;
        font-weight: ${(props) => props.theme.fonts.bold};
        color: ${(props) => props.theme.colors.white};
        padding: 6px 10px;
        font-size: 1rem;
        width: 10%;
        margin: 0;
        min-width: 60px;
        transition: background-color, opacity,
            ${(props) => props.theme.transitions.transition};

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
            background-color: ${(props) => props.theme.colors.midGrey};
        }

        @media ${device.tabletPortrait} {
            padding: 7px;
            margin: 2px;
            font-size: 1.2rem;
            min-width: 70px;
        }
    }
`;

export default StyledMessageInput;
