import styled from 'styled-components';

const Panel = styled.div`
    margin-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.midGrey};
    padding-bottom: 1rem;

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 2rem;
    }

    .rc-slider {
        margin: 0 2rem;
    }
`;

export const SliderButton = styled.button`
    background: none;
    appearance: none;
    width: 30px;
    font-weight: ${(props) => props.theme.fonts.bold};
    z-index: 5;
    position: relative;
    border: 1px solid ${(props) => props.theme.colors.midGrey};
`;

export const SliderWrapper = styled.div`
    display: flex;
    margin-top: 3.5rem;
`;

export const SkillsHeadings = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};

    h4 {
        margin-bottom: 0;
    }
`;

export const Skill = styled.div`
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};

    &:last-child {
        border-bottom: 0;
    }

    > div {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    p {
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 5px;
    }

    .react-toggle-track-check {
        margin-top: 0;
        margin-bottom: 0;
        top: 7px;
    }
`;

export default Panel;
