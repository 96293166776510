import React from 'react';

import TertiaryButton from 'components/TertiaryButton';
import Tiler, { Tile } from 'components/Tiler';
import FeelingSlider from 'components/FeelingSlider';

import TileHeader, {
    TileHeading,
    TileCopy,
    TileProgressBar,
    TileContent,
} from 'components/Tiler/styled';
import StyledFeedbackRoom from './styled';

type Props = {
    chatID: string;
    handleFeedback: () => void;
    handleExit: (destination?: string) => void;
    handlePreFeedback: (score: number) => void;
    handlePostFeedback: (score: number) => void;
    handleTileChange: (tile: string) => void;
};
const FeedbackRoom = ({
    chatID,
    handleFeedback,
    handleExit,
    handlePreFeedback,
    handlePostFeedback,
    handleTileChange,
}: Props) => {
    const onFeedback = () => {
        handleFeedback();
        handleExit(process.env.REACT_APP_FORM_DESTINIATION + chatID);
    };
    const {
        REACT_APP_NO_FEEDBACK_DESTINATION: noFeedbackDestination,
        REACT_APP_POST_CHAT_DESTINATION: postChatDestination,
    } = process.env;
    const skippedFeedbackDestination =
        noFeedbackDestination || postChatDestination || '/';
    const skippedFeedbackLabel = noFeedbackDestination ? (
        <>
            No, go to
            <br />
            Samaritans.org
        </>
    ) : (
        'No'
    );

    return (
        <StyledFeedbackRoom>
            <h1 hidden>Feedback room</h1>
            <Tiler onChange={handleTileChange}>
                <Tile
                    name="thank-you"
                    nextButtonText="Yes, continue"
                    content={
                        <>
                            <TileHeader>
                                <h3>Your conversation has ended</h3>
                            </TileHeader>
                            <TileContent>
                                <TileHeading bold>
                                    Thank you for being a part of our pilot
                                </TileHeading>
                                <p>We hope it helped you in some way</p>
                                <TileCopy>
                                    Would you be able to give us some feedback
                                    to help us improve our web chat service?
                                </TileCopy>
                                <small>
                                    We'll use your feedback to help us learn how
                                    we can make the service better, as we're
                                    aiming to offer it to more people in the
                                    future.
                                </small>
                            </TileContent>
                        </>
                    }
                    otherButtons={
                        <TertiaryButton
                            action={() =>
                                handleExit(skippedFeedbackDestination)
                            }
                            dataIdentifier="skipFeedback"
                        >
                            {skippedFeedbackLabel}
                        </TertiaryButton>
                    }
                />
                <Tile
                    name="pre-feedback"
                    nextButtonText="Continue"
                    content={
                        <>
                            <TileHeader>
                                <p>1 of 3</p>
                                <TileProgressBar>
                                    <div style={{ width: '33%' }}></div>
                                </TileProgressBar>
                            </TileHeader>
                            <TileContent>
                                <TileHeading>
                                    How were you feeling <i>before</i> talking
                                    to a Samaritan?
                                </TileHeading>
                                <FeelingSlider
                                    updateFeeling={handlePreFeedback}
                                    ariaLabel="How were you feeling before talking to a Samaritan?"
                                />
                            </TileContent>
                        </>
                    }
                />
                <Tile
                    name="post-feedback"
                    goBack
                    onNext={onFeedback}
                    nextButtonText="Continue to survey"
                    content={
                        <>
                            <TileHeader>
                                <p>2 of 3</p>
                                <TileProgressBar>
                                    <div style={{ width: '66%' }}></div>
                                </TileProgressBar>
                            </TileHeader>
                            <TileContent>
                                <TileHeading>
                                    How are you feeling <i>after</i> talking to
                                    a Samaritan?
                                </TileHeading>
                                <FeelingSlider
                                    updateFeeling={handlePostFeedback}
                                    ariaLabel="How were you feeling after talking to a Samaritan?"
                                />
                            </TileContent>
                        </>
                    }
                />
            </Tiler>
        </StyledFeedbackRoom>
    );
};

export default FeedbackRoom;
