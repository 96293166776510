import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Detector } from 'react-detect-offline';

import { USE_DEBUG } from 'chatConstants';
import { PAGE_UNLOAD } from 'actionTypes';
import LandingRoom from 'containers/LandingRoom';
import WaitingRoom from 'containers/WaitingRoom';
import ChatRoom from 'containers/ChatRoom';
import FeedbackRoom from 'containers/FeedbackRoom';
import ErrorModal from 'components/ErrorModal';
import DebugToolbar from 'components/DebugToolbar';
import CallerConnectionBar from 'containers/CallerConnectionBar';
import ServiceUnavailablePanel from 'components/ServiceUnavailablePanel';
import ConnectionPanel from 'components/ConnectionPanel';
import RemovedFromQueue from 'components/RemovedFromQueue';
import Barred from 'components/Barred';
import ServerErrorPanel from 'components/ServerErrorPanel';
import BusyPanel from 'components/BusyPanel';
import FullScreenPage from 'components/FullScreenPage';

import { ConnectionStatus } from 'reducers/connection';
import { attemptJoinQueue, Status, State, Room } from 'reducers/chat';

import { PING_URL } from 'chatConstants';
import { AppDispatch } from 'reduxStore';
import { RootState } from 'reducers';

export type Props = {
    serviceAvailable: boolean;
    chatID: null | string;
    activeRoom: Room;
    readyToStart: boolean;
    connected: boolean;
    ended: boolean;
    barred: boolean;
    error: boolean;
    volunteerPresent: boolean;
    joinAction: () => void;
    onUnload: () => void;
    loading: boolean;
    busy: boolean;
};

export const CallerChatApp = ({
    serviceAvailable,
    chatID,
    activeRoom,
    connected,
    ended,
    barred,
    error,
    onUnload,
    volunteerPresent,
    joinAction,
    busy,
    loading,
}: Props) => {
    const [previouslyTimedOut, setHasTimedout] = useState(false);
    const connectionLost = !connected && !!chatID && !ended;

    useEffect(() => {
        let timer: undefined | number;
        const hasErrored = typeof error === 'boolean' && !!error;
        if ((hasErrored || !connected) && !previouslyTimedOut) {
            timer = window.setTimeout(() => {
                setHasTimedout(true);
            }, 30000);
            return () => clearTimeout(timer);
        } else if (!!chatID) {
            setHasTimedout(false);
            clearTimeout(timer);
        }
    }, [error, connected, chatID, previouslyTimedOut]);

    useEffect(() => {
        const handleBeforeunload = () => {
            onUnload();
        };
        window.addEventListener('beforeunload', handleBeforeunload);
        return () =>
            window.removeEventListener('beforeunload', handleBeforeunload);
    }, [onUnload]);

    if (!serviceAvailable && !chatID && !loading) {
    }

    const renderErrorPanels = () => {
        if (barred) {
            return <Barred />;
        } else if (busy) {
            return <BusyPanel />;
        } else if (error) {
            return <ServerErrorPanel moreInfo={previouslyTimedOut} />;
        } else if (!serviceAvailable) {
            return <ServiceUnavailablePanel />;
        }
    };

    const renderActiveRoom = (navigatorOnline: boolean) => {
        switch (activeRoom) {
            case Room.LANDING:
                return (
                    <>
                        {renderErrorPanels()}
                        {(serviceAvailable || loading) && <LandingRoom />}
                    </>
                );
            case Room.WAITING:
                return (
                    <>
                        {renderErrorPanels()}
                        {ended && (
                            <RemovedFromQueue
                                volunteerExit={!volunteerPresent}
                                joinAction={joinAction}
                            />
                        )}
                        {(connectionLost || !navigatorOnline) && (
                            <ConnectionPanel moreInfo={previouslyTimedOut} />
                        )}
                        <WaitingRoom moreInfo={previouslyTimedOut} />
                    </>
                );
            case Room.CHAT:
                return (
                    <ChatRoom
                        connectionBar={
                            <CallerConnectionBar
                                navigatorOnline={navigatorOnline}
                            />
                        }
                        moreInfo={previouslyTimedOut}
                        navigatorOnline={navigatorOnline}
                    />
                );
            case Room.FEEDBACK:
                return <FeedbackRoom />;
            default:
                return null;
        }
    };

    return (
        <>
            <FullScreenPage visible={loading} text="Loading" />

            <ErrorModal />

            <Detector
                polling={{ url: PING_URL }}
                render={({ online }: { online: boolean }) =>
                    renderActiveRoom(online)
                }
            />

            {USE_DEBUG && <DebugToolbar />}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: state.chat.status === Status.LOADING,
    chatID: state.chat.data.id,
    readyToStart: state.chat.state === State.STARTED,
    activeRoom: state.chat.room,
    connected: state.connection.status === ConnectionStatus.ESTABLISHED,
    ended: state.chat.state === State.ENDED,
    serviceAvailable: state.chat.status === Status.ONLINE,
    barred: state.chat.status === Status.BARRED,
    error: state.chat.status === Status.ERRORED,
    volunteerPresent: !state.chat.data.volunteerPresent,
    busy: state.chat.status === Status.BUSY,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    joinAction: () => dispatch(attemptJoinQueue()),
    onUnload: () => dispatch({ type: PAGE_UNLOAD }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallerChatApp);
