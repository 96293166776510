import React from 'react';

import StartLabel from './styled';

const ConnectionStartChat = ({ label }) => (
    <StartLabel>
        <p>{label}</p>
    </StartLabel>
);

export default ConnectionStartChat;
