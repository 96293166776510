import { connect } from 'react-redux';

import FeedbackRoom from 'components/FeedbackRoom';
import { endSession, setPreScore, setPostScore } from 'reducers/chat';
import { submitFeedback } from 'network/actions';
import { AppDispatch } from 'reduxStore';
import { RootState } from 'reducers';
import { changeTile } from 'reducers/actions';

const mapStateToProps = (state: RootState) => ({
    chatID: state.chat.data.id as string,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleExit: (destination?: string) => dispatch(endSession(destination)),
    handleFeedback: () => dispatch(submitFeedback()),
    handlePreFeedback: (score: number) => dispatch(setPreScore(score)),
    handlePostFeedback: (score: number) => dispatch(setPostScore(score)),
    handleTileChange: (tile: string) => dispatch(changeTile(tile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackRoom);
