import styled from 'styled-components';
import StyledMessage from 'components/Message/styled';

const StyledTypingIndicator = styled(StyledMessage)`
    @keyframes blink {
        50% {
            opacity: 1;
        }
    }

    display: flex;
    align-items: center;
    padding: 12px;

    &::after {
        top: 11px;
    }

    > div {
        width: 0.5em;
        height: 0.5em;
        margin: 0 2px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.darkGrey};
        opacity: 0.4;

        &:nth-of-type(1) {
            animation: 1s blink infinite 0.333s;
        }

        &:nth-of-type(2) {
            animation: 1s blink infinite 0.666s;
        }

        &:nth-of-type(3) {
            animation: 1s blink infinite 0.999s;
        }

        /* fallback for Edge */
        @supports (-ms-ime-align: auto) {
            display: inline-block;
        }

        /* fallback for IE */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: inline-block;
        }
    }
`;

export default StyledTypingIndicator;
