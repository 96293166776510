import React from 'react';
import PropTypes from 'prop-types';

import StyledRoom from './styled';

const Room = ({ children }) => <StyledRoom>{children}</StyledRoom>;

Room.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Room;
