import React, { ReactNode } from 'react';

import StatusMessage from 'components/StatusMessage';
import LocalMessage from 'components/LocalMessage';
import RemoteMessage from 'components/RemoteMessage';
import StyledTextMessageStatus, { StyledRetryLink } from './styled';

import { MessageStatus, MessageOrigin } from 'reducers/messages';

export type Props = {
    text: string;
    status: MessageStatus;
    origin: MessageOrigin;
    onRetry: () => void;
};

const TextMessage = ({ text, status, origin, onRetry }: Props) => {
    const isLocal = origin === MessageOrigin.CLIENT;

    const StatusMapping: { [status in MessageStatus]: ReactNode } = {
        [MessageStatus.UNSENT]: '',
        [MessageStatus.CLIENT]: 'Sending',
        [MessageStatus.SYSTEM]: 'Sent',
        [MessageStatus.REMOTE]: 'Delivered',
        [MessageStatus.FAILED]: (
            <>
                Failed to send.
                <StyledRetryLink
                    aria-label={`The following message failed to send: ${text}. Retry?`}
                    onClick={onRetry}
                >
                    Retry?
                </StyledRetryLink>
            </>
        ),
    };

    const ModifierMapping: { [status in MessageStatus]: string } = {
        [MessageStatus.UNSENT]: '',
        [MessageStatus.CLIENT]: 'sending',
        [MessageStatus.SYSTEM]: 'sent',
        [MessageStatus.REMOTE]: 'delivered',
        [MessageStatus.FAILED]: 'failed',
    };

    let statusText = StatusMapping[status];

    let modifier = ModifierMapping[status];

    const MessageComponent = (() => {
        switch (origin) {
            case MessageOrigin.SYSTEM:
                return StatusMessage;
            case MessageOrigin.REMOTE:
                return RemoteMessage;
            default:
                return LocalMessage;
        }
    })();

    return (
        <>
            <MessageComponent modifier={modifier}>{text}</MessageComponent>
            {isLocal && statusText && (
                <StyledTextMessageStatus>{statusText}</StyledTextMessageStatus>
            )}
        </>
    );
};

export default TextMessage;
