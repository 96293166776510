import React, { useState } from 'react';
import Dock from 'react-dock';
import DebugContent from './DebugContent';
import useWindowSize from '@rehooks/window-size';

import Label, { Sidebar, Close } from './styled';

const DebugToolbar = () => {
    let size;
    const windowSize = useWindowSize();
    const [isVisible, setVisibility] = useState(false);
    const isMobile = windowSize.innerWidth < 1024;

    isMobile ? (size = 0.7) : (size = 0.3);

    return (
        <div>
            <Label onClick={() => setVisibility(!isVisible)}>Debug</Label>
            <Dock
                isVisible={isVisible}
                position="right"
                dimMode="none"
                size={size}
            >
                <Sidebar>
                    <Close onClick={() => setVisibility(!isVisible)}>
                        &#10005;
                    </Close>
                    <DebugContent />
                </Sidebar>
            </Dock>
        </div>
    );
};

export default DebugToolbar;
