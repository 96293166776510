import { connect } from 'react-redux';

import { AppDispatch } from 'reduxStore';
import MessageInput from '../components/MessageInput';
import { processNewMessage } from 'reducers/messages';
import { processTyping, processInactivity } from 'reducers/actions';

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onSubmit: (message: string) => dispatch(processNewMessage(message)),
    typingStatus: (status: boolean) => dispatch(processTyping(status)),
    inactive: (status: boolean, duration: number) =>
        dispatch(processInactivity({ status, duration })),
});

export default connect(null, mapDispatchToProps)(MessageInput);
