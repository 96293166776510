import React, { ReactNode } from 'react';
import { useSpring, animated, config } from 'react-spring';

import StyledBarWithIcon from './styled';

type Props = {
    children: ReactNode;
    icon: ReactNode;
};

const BarWithIcon = ({ children, icon }: Props) => {
    const styles = useSpring({
        transform: 'translate3d(0, 0, 0)',
        from: { transform: 'translate3d(0, 100%, 0)' },
        config: config.gentle,
    });

    return (
        <animated.div style={styles}>
            <StyledBarWithIcon>
                {icon}
                {children}
            </StyledBarWithIcon>
        </animated.div>
    );
};

export default BarWithIcon;
