import React, { ReactNode, useState, useEffect } from 'react';
import { useThrottle } from 'use-throttle';
import { isIOS, isIE, isAndroid } from 'react-device-detect';

import ChatInput from 'containers/ChatInput';

import { ReactComponent as WarningIcon } from 'assets/svgs/warning.svg';

import Banner from 'components/Banner';
import Button from 'components/Button';
import ExitModal from 'components/ExitModal';
import ConnectionStartChat from 'components/ConnectionStartChat';
import MobileMenu from 'components/MobileMenu';
import ConnectionWarningBar from 'components/ConnectionWarningBar';
import ChatEndedPanel from 'components/ChatEndedPanel';
import MessageList from 'components/MessageList';

import { audioNotification, chatNotification } from 'notifications';
import BannerNotifications from 'components/BannerNotifications';

import StyledChatRoom, {
    ChatRoomStatus,
    ChatRoomExitButton,
    ChatRoomHeader,
} from './styled';
import { Message } from 'reducers/messages';

type Props = {
    handleExit: (started: boolean) => void;
    connectionBar: ReactNode;
    connected: boolean;
    serverError: boolean;
    ended: boolean;
    started: boolean;
    moreInfo: boolean;
    notifications: boolean;
    audio: boolean;
    navigatorOnline: boolean;
    joinAction: () => void;
    updateNotifications: (state: boolean) => void;
    updateAudio: (state: boolean) => void;
    volunteerExit: boolean;
    messages: Message[];
    messageRetry: (messageID: string) => void;
    typing: boolean;
};

const ChatRoom = ({
    handleExit,
    connectionBar,
    connected,
    serverError,
    ended,
    started,
    moreInfo,
    notifications,
    audio,
    volunteerExit,
    navigatorOnline,
    joinAction,
    updateNotifications,
    updateAudio,
    typing,
    messages,
    messageRetry,
}: Props) => {
    const [exitModalOpen, setModalVisibility] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const throttledScroll = useThrottle(hasScrolled, 600);
    const online = connected && !serverError;
    const offline = !connected || serverError || ended || !navigatorOnline;

    let status;

    if (!started) {
        status = 'Not Started';
    } else if (offline) {
        status = 'Offline';
    }

    const exit = (started: boolean) => {
        handleExit(started);
    };

    useEffect(() => {
        if (started && notifications) {
            chatNotification();
        }

        if (started && audio) {
            audioNotification.play();
        }
    }, [started, audio, notifications]);

    const ConnectionStartLabel = started
        ? 'Messages will appear below'
        : 'When a volunteer joins they will see your messages';

    return (
        <>
            <Banner
                showNotifications={!started}
                notifications={notifications}
                audio={audio}
                updateAudio={updateAudio}
                updateNotifications={updateNotifications}
            />
            <StyledChatRoom>
                <h1 hidden>Chat Room</h1>
                <ChatRoomHeader status={status}>
                    <MobileMenu>
                        <h3>Other ways to get in touch</h3>
                        <p>
                            Call us:{' '}
                            <a
                                aria-label="Samaritans phone number on 116 123"
                                href="tel:116123"
                            >
                                116 123
                            </a>
                        </p>
                        <p>
                            Email us:{' '}
                            <a href="mailto:jo@samaritans.org">
                                jo@samaritans.org
                            </a>
                        </p>
                        {!isIOS && !isIE && !isAndroid && (
                            <>
                                <h4>Notification settings</h4>
                                <BannerNotifications
                                    updateNotifications={updateNotifications}
                                    updateAudio={updateAudio}
                                    audio={audio}
                                    notifications={notifications}
                                />
                            </>
                        )}
                    </MobileMenu>
                    <ChatRoomStatus>{connectionBar}</ChatRoomStatus>
                    <ChatRoomExitButton
                        onClick={() => setModalVisibility(!exitModalOpen)}
                    >
                        Exit
                    </ChatRoomExitButton>
                </ChatRoomHeader>
                <MessageList
                    showTyping={typing}
                    setHasScrolled={setHasScrolled}
                    connectionStartChat={
                        <ConnectionStartChat label={ConnectionStartLabel} />
                    }
                    messages={messages}
                    messageRetry={messageRetry}
                />
                {!online && !ended && (
                    <ConnectionWarningBar
                        moreInfo={moreInfo}
                        serverError={serverError}
                    />
                )}
                {volunteerExit && (
                    <ChatEndedPanel hasScrolled={false} aria-live="polite">
                        <div>
                            <p>
                                <b>
                                    The volunteer has left the chat, and your
                                    conversation has ended.
                                </b>
                            </p>
                            <p>
                                When you are ready, please exit the chat. You
                                might also find our online support materials
                                helpful. If you need urgent support you can call
                                us on <a href="tel:116123">116 123</a>.
                            </p>
                        </div>
                    </ChatEndedPanel>
                )}
                {ended && !online && !volunteerExit && (
                    <ChatEndedPanel
                        hasScrolled={throttledScroll}
                        aria-live="polite"
                    >
                        <WarningIcon aria-hidden="true" />
                        <div>
                            <p>
                                <b>
                                    Unfortunately, your chat has ended because
                                    we couldn't reach you
                                </b>
                            </p>
                            <p>
                                This might have happened because you’ve lost
                                signal or are having problems with your wifi
                                connection.
                            </p>
                        </div>
                        <Button action={joinAction}>Rejoin waiting room</Button>
                        <p>
                            <b>
                                or call free anytime, from any phone on{' '}
                                <a href="tel:116123">116 123</a>
                            </b>
                        </p>
                    </ChatEndedPanel>
                )}
                <ChatInput disabled={ended} />
                <ExitModal
                    room="Chat"
                    handleCancel={() => setModalVisibility(!exitModalOpen)}
                    handleExit={() => exit(started || ended)}
                    exitModalOpen={exitModalOpen}
                />
            </StyledChatRoom>
        </>
    );
};

export default ChatRoom;
