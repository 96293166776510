import React from 'react';
import { connect } from 'react-redux';
import { Detector } from 'react-detect-offline';

import VolunteerChatRoom from 'containers/VolunteerChatRoom';
import VolunteerConnectionBar from 'containers/VolunteerConnectionBar';

const VolunteerChatPage = () => (
    <Detector
        render={({ online }) => (
            <VolunteerChatRoom
                connectionBar={
                    <VolunteerConnectionBar navigatorOnline={online} />
                }
                navigatorOnline={online}
            />
        )}
    />
);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerChatPage);
