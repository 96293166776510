import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import LinkButton from 'components/LinkButton';

import StyledSentryBoundary from './styled';

class SentryBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.error) {
            return (
                <StyledSentryBoundary>
                    <h1 hidden>Error</h1>
                    <h2>
                        We're sorry - something has
                        <br /> gone wrong.
                    </h2>
                    <p>Our team has been notified.</p>
                    <p>
                        We understand this is frustrating and we’re doing
                        everything we can to fix the problem.
                    </p>
                    <p>
                        You can call us free any time on{' '}
                        <a
                            aria-label="Samaritans phone number 116 123"
                            href="tel:116123"
                        >
                            116 123
                        </a>
                        .
                    </p>
                    <LinkButton
                        centered={true}
                        href={process.env.REACT_APP_SERVICES_DESTINIATION}
                    >
                        Find another service
                    </LinkButton>
                </StyledSentryBoundary>
            );
        } else {
            return this.props.children;
        }
    }
}

export default SentryBoundary;
