import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CloseEvent } from 'reconnecting-websocket';

import { AppThunk } from 'reduxStore';
import { RECONNECT_TIMEOUT_LIMIT } from 'chatConstants';

import { backToVolunteerStart } from 'actions/volunteer';
import { clearPreferences } from 'reducers/app';
import network from 'network';
import {
    setReady,
    State,
    setOffline,
    setEnded,
    setBusy,
    setBarred,
    showError,
} from 'reducers/chat';
import { resetChat } from 'reducers/actions';
import { receiveMessage, ReceivedMessage } from 'network/actions';

export enum ConnectionStatus {
    ESTABLISHED = 'ESTABLISHED',
    ATTEMPTED = 'ATTEMPTED',
    ERRORED = 'ERRORED',
    DROPPED = 'DROPPED',
    CLOSED = 'CLOSED',
}

const url = createSlice({
    name: 'connection',
    initialState: '',
    reducers: {
        setUrl: (state, action: PayloadAction<string>) => {
            return action.payload;
        },
    },
});

export const { setUrl } = url.actions;

const status = createSlice({
    name: 'connection',
    initialState: ConnectionStatus.DROPPED as ConnectionStatus,
    reducers: {
        setDropped: () => ConnectionStatus.DROPPED,
        setAttempted: () => ConnectionStatus.ATTEMPTED,
        setEstablished: () => ConnectionStatus.ESTABLISHED,
        setClosed: () => ConnectionStatus.CLOSED,
    },
});

export const { setEstablished, setClosed, setDropped, setAttempted } =
    status.actions;

export default combineReducers({
    url: url.reducer,
    status: status.reducer,
});

export const attemptConnection =
    (reset?: boolean): AppThunk =>
    async (dispatch, getState) => {
        if (reset) {
            await dispatch(closeConnection());
        }

        dispatch(setAttempted());
        const state = getState();
        const chatUrl = state.connection.url;
        const handlers = {
            open: () => dispatch(handleEstablishedConnection()),
            close: (err: CloseEvent) => dispatch(handleDroppedConnection(err)),
            attempt: () => dispatch(attemptConnection()),
            receive: (message: ReceivedMessage) =>
                dispatch(receiveMessage(message)),
        };
        try {
            network.init(chatUrl, handlers);
        } catch {
            dispatch(dropped());
        }
    };

export const closeConnection = (): AppThunk => async (dispatch) => {
    // Planned closure of the connection
    await network.close();
    dispatch(setClosed());
};

export const attemptReconnect = (): AppThunk => async (dispatch) => {
    if (network.hasConnected()) {
        await dispatch(attemptConnection());
    } else {
        // New chat - make sure we are in a fresh state
        dispatch(setReady());
    }
};

export const dropped = (): AppThunk => async (dispatch) => {
    // Unplanned disconnection from the server
    dispatch(setReady());
    dispatch(setDropped());
};

// Handling of timeout from chat
let chatEndedTimer: number | undefined;

const startTimeout = (): AppThunk => async (dispatch) => {
    chatEndedTimer = window.setTimeout(async () => {
        await network.close();
        dispatch(setEnded());
    }, RECONNECT_TIMEOUT_LIMIT);
};

const stopTimeout = (): AppThunk => async () => {
    window.clearTimeout(chatEndedTimer);
    chatEndedTimer = undefined;
};

export const handleEstablishedConnection =
    (): AppThunk => async (dispatch, getState) => {
        const state = getState();
        if (state.chat.state !== State.ENDED) {
            dispatch(stopTimeout());
        }
        dispatch(setEstablished());
    };

export const handleDroppedConnection =
    (err: CloseEvent): AppThunk =>
    async (dispatch) => {
        dispatch(dropped());
        if (err) {
            if (err.code === 4000) {
                // INVALID JOIN
                dispatch(clearPreferences());
                dispatch(attemptConnection());
            } else if (err.code === 4001) {
                // INVALID JOIN ID
                dispatch(resetChat());
            } else if (err.code === 4002) {
                // LATE RETURN
                dispatch(setEnded());
            } else if (err.code === 4010) {
                // SERVICE UNAVAILABLE
                dispatch(setOffline());
            } else if (err.code === 4011) {
                // SERVICE BUSY
                dispatch(setBusy());
            } else if (err.code === 4020) {
                // CALLER BLOCKED
                dispatch(setBarred());
            } else if (err.code === 4900) {
                // VOLUNTEER: EMPTY QUEUE
                dispatch(backToVolunteerStart());
                dispatch(showError());
            } else {
                if (!chatEndedTimer) {
                    dispatch(startTimeout());
                }
            }
        }
    };
