import React from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated, config } from 'react-spring';

import ChatEndedBar from 'components/ChatEndedBar';

import StyledChatEndedPanel from './styled';

const ChatEndedPanel = ({ hasScrolled, children }) => {
    const transitions = useTransition(hasScrolled, null, {
        from: { transform: 'translate3d(0,100%,0)' },
        enter: { transform: 'translate3d(0,0,0)' },
        leave: { display: 'none' },
        config: config.gentle,
    });

    return (
        <>
            {transitions.map(({ item, key, props }) =>
                item ? (
                    <animated.div key={key} style={props}>
                        <ChatEndedBar />
                    </animated.div>
                ) : (
                    <animated.div key={key} style={props}>
                        <StyledChatEndedPanel>{children}</StyledChatEndedPanel>
                    </animated.div>
                ),
            )}
        </>
    );
};

ChatEndedPanel.propTypes = {
    hasScrolled: PropTypes.bool.isRequired,
};

export default ChatEndedPanel;
