import React from 'react';
import { connect } from 'react-redux';

import { clearError } from 'reducers/chat';
import ButtonModal from 'components/ButtonModal';
import Button from 'components/Button';
import { RootState } from 'reducers';

type Props = {
    open: boolean;
    handleOK: () => void;
} & typeof defaultProps;

const defaultProps = {
    text: 'Something went wrong.' as string,
};

export const ErrorModal = ({ open, text, handleOK }: Props) => {
    return (
        <ButtonModal
            open={open}
            title={text}
            buttons={<Button action={handleOK}>Ok</Button>}
        />
    );
};
ErrorModal.defaultProps = defaultProps;

const mapStateToProps = (state: RootState) => ({
    open: state.chat.errors.visible,
    text: state.chat.errors.message,
});

const mapDispatchToProps = {
    handleOK: clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
