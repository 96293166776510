import styled from 'styled-components';

const StyledStatusMessage = styled.div`
    font-size: ${(props) => props.theme.fonts.s};
    margin: ${(props) => props.theme.grid.baseGrid} 0;
    color: ${(props) => props.theme.colors.body};
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.8rem 1rem;
    background-color: ${(props) => props.theme.colors.tertiary};
`;

export default StyledStatusMessage;
