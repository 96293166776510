import styled from 'styled-components';

const StartLabel = styled.li`
    font-size: 15px;
    width: 90%;
    margin: 0px 10px;
    align-items: center;
    text-align: center;
    margin: 0 auto ${(props) => props.theme.grid.baseGrid};
    top: 0;

    p {
        position: relative;
        transition: opacity ${(props) => props.theme.transitions.transition};
        color: ${(props) => props.theme.colors.black};
        border-bottom: 1px solid ${(props) => props.theme.colors.lightMidGrey};
        display: block;
        padding: calc(${(props) => props.theme.grid.baseGrid} / 2);
    }
`;

export default StartLabel;
