import React from 'react';

import { ReactComponent as WarningIcon } from 'assets/svgs/warning.svg';
import WhiteRoomCoverPanel from 'components/RoomCoverPanels/White';
import Button from 'components/Button';

const RemovedFromQueue = ({ volunteerExit, joinAction }) => (
    <WhiteRoomCoverPanel>
        <WarningIcon />
        <h2>
            We can’t reach you. Unfortunately, this means you’re not in the
            waiting room anymore.
        </h2>
        {volunteerExit ? (
            <p>
                A Samaritan was waiting for you but you didn’t enter the chat.
            </p>
        ) : (
            <p>
                This might have happened because you’ve lost signal or are
                having problems with your WiFi connection.
            </p>
        )}
        <Button action={joinAction}>Rejoin waiting room</Button>
        <p>
            <b>
                You can call us for free, any time, day or night, on{' '}
                <a
                    aria-label="Samaritans phone number 116 123"
                    href="tel:116123"
                >
                    116 123
                </a>
            </b>
        </p>
    </WhiteRoomCoverPanel>
);

export default RemovedFromQueue;
