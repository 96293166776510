import { createAction, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AppThunk } from 'reduxStore';

import { Message } from 'reducers/messages';
import { State } from 'reducers/chat';

// Will only dispatch an action if the app is in a state to accept it
const sendIfStarted = <T extends any>(func: ActionCreatorWithPayload<T>) => {
    return (value: T): AppThunk =>
        async (dispatch, getState) => {
            let state = getState();

            let chatStarted = state.chat.state === State.STARTED;
            // Send if connected
            if (chatStarted) {
                dispatch(func(value));
            }
        };
};

export const resetChat = createAction('action/resetChat');

export const attemptJoin = createAction('action/attemptToJoinQueue');

export const attemptJoinFromReload = createAction(
    'action/attemptToJoinQueueReload',
);

// Actions to take when the user receives a chatID
// Update Room
// Update State
// Update chatID
export const joinedQueue = createAction<string>('action/joinedQueue');

// Actions to take when the user already has a  chatID
// Update Room
// Update State
// Record where we joined
// Update chatID
export const joinedQueueFromReload = createAction<string>(
    'action/joinedQueueReload',
);

// Actions to take when a user is starting the chat
// Ensure we are in the chat room
// Notify backend we are in the chat room
// Remove notification preferences, we no longer need these
export const enteredChat = createAction('action/enterChat');

// Update Room to Chat
// Update Status to Online
// Update State to Chat
export const enteredChatFromReload = createAction('action/enterChatReload');

export const exitedChat = createAction('action/exitChat');

export const typing = createAction<boolean>('action/typingStatus');

export const processTyping = sendIfStarted(typing);

export const sendMessage = createAction<Message>('action/sendingMessage');

export const inactivity =
    createAction<{ status: boolean; duration: number }>('action/inactivity');

export const processInactivity = sendIfStarted(inactivity);

export const sendFeedback = createAction<{ pre: number; post: number }>(
    'action/submitFeedback',
);

export const changeTile = createAction<string>('helper/changedTile');
