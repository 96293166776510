import styled from 'styled-components';

const StyledTextMessageStatus = styled.span`
    color: ${(props) => props.theme.colors.midGrey};
    font-size: 13px;
    margin: 8px 5px 0 0;
    display: flex;
    justify-content: flex-end;
    cursor: default;
`;
StyledTextMessageStatus.displayName = 'TextMessageStatus';

export const StyledRetryLink = styled.button`
    margin-left: 0.3em;
    color: ${(props) => props.theme.colors.red};
    text-decoration: underline;
    background: none;
    border: 0;
    padding: 0;

    ${(props) => props.theme.hover} {
        text-decoration: none;
    }
`;

export default StyledTextMessageStatus;
