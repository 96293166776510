import React from 'react';
import { connect } from 'react-redux';
import { useTransition, animated, config } from 'react-spring';

import SafeguardingBannerContainer from 'containers/SafeguardingBannerContainer';
import JoiningQueue from 'components/JoiningQueue';
import InQueue from 'components/InQueue';
import ReadyScreen from 'components/ReadyScreen';
import Banner from 'components/Banner';

import {
    enableAudio,
    disableAudio,
    enableNotifications,
    disableNotifications,
} from 'reducers/app';
import { State, setChatRoom } from 'reducers/chat';
import { enteredChat, changeTile } from 'reducers/actions';
import { AppDispatch } from 'reduxStore';
import { RootState } from 'reducers';
import { useIsMobile } from 'utils/hooks';

export type Props = {
    readyToStart: boolean;
    joining: boolean;
    joinChat: () => void;
    notifications: boolean;
    waitTime: number | null;
    updateNotifications: (value: boolean) => void;
    goToChat: () => void;
    moreInfo: boolean | undefined;
    audio: boolean;
    updateAudio: (value: boolean) => void;
    handleTileChange: (tile: string) => void;
};

export const WaitingRoom = ({
    readyToStart,
    joining,
    waitTime,
    audio,
    updateAudio,
    notifications,
    updateNotifications,
    goToChat,
    moreInfo,
    handleTileChange,
    joinChat,
}: Props) => {
    const isMobile = useIsMobile();

    const joiningTransition = useTransition(joining, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.gentle,
    });

    const startingTransition = useTransition(readyToStart, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    });

    return (
        <>
            <Banner
                showNotifications={true}
                notifications={notifications}
                audio={audio}
                updateAudio={updateAudio}
                updateNotifications={updateNotifications}
            />
            {!isMobile && <SafeguardingBannerContainer />}
            {joiningTransition.map(({ item, key, props }) =>
                item ? (
                    <animated.div key={key} style={props}>
                        <JoiningQueue moreInfo={moreInfo} />
                    </animated.div>
                ) : (
                    <animated.div key={key} style={props}>
                        {startingTransition.map(({ item, key, props }) =>
                            item ? (
                                <animated.div key={key} style={props}>
                                    <ReadyScreen
                                        joinAction={joinChat}
                                        notifications={notifications}
                                        audio={audio}
                                    />
                                </animated.div>
                            ) : (
                                <animated.div key={key} style={props}>
                                    <InQueue
                                        waitTime={waitTime}
                                        goToChat={goToChat}
                                        notifications={notifications}
                                        updateNotifications={
                                            updateNotifications
                                        }
                                        handleTileChange={handleTileChange}
                                        updateAudio={updateAudio}
                                        audio={audio}
                                    />
                                </animated.div>
                            ),
                        )}
                    </animated.div>
                ),
            )}
            {isMobile && <SafeguardingBannerContainer />}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    readyToStart: state.chat.state === State.STARTED,
    joining: state.chat.state === State.JOINING,
    notifications: state.app.notifications,
    waitTime: state.chat.waitTime.queue,
    audio: state.app.audio,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    updateNotifications: (value: boolean) =>
        dispatch(value ? enableNotifications() : disableNotifications()),
    updateAudio: (value: boolean) =>
        dispatch(value ? enableAudio() : disableAudio()),
    goToChat: () => dispatch(setChatRoom()),
    joinChat: () => dispatch(enteredChat()),
    handleTileChange: (tile: string) => dispatch(changeTile(tile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitingRoom);
