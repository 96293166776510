import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Settings } from 'chatConstants';

const settings = createSlice({
    name: 'settings',
    initialState: { safeguarding_banner: null } as Settings,
    reducers: {
        setSettings: (state, { payload }: PayloadAction<Settings>) => payload,
    },
});

export const { setSettings } = settings.actions;
export default settings.reducer;
