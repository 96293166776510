import styled from 'styled-components';

const StyledMessageList = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    overflow: auto;

    /* Allow scrolling in Edge */
    @supports (-ms-ime-align: auto) {
        > div {
            min-height: 0;
        }
    }

    /* Allow scrolling in IE11 */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        > div {
            min-height: 0;
            overflow: auto;
        }
    }

    ul {
        list-style: none;
        padding: 0 55px;
        margin-bottom: 0;
        position: relative;

        > li {
            padding-bottom: 0.2em;

            &:first-child {
                margin-top: auto;
            }
        }
    }
`;

export default StyledMessageList;
