import React from 'react';

import { ReactComponent as VolunteerAvatar } from 'assets/svgs/volunteer-avatar-alt.svg';
import StyledTypingIndicator from './styled';

const TypingIndicator = () => (
    <StyledTypingIndicator
        aria-live="polite"
        aria-label="The volunteer is typing"
    >
        <VolunteerAvatar aria-hidden="true" />
        <div />
        <div />
        <div />
    </StyledTypingIndicator>
);

export default TypingIndicator;
