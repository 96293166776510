import React from 'react';

import StyledFullScreenPage from './styled';

const FullScreen = ({ visible, text }) => {
    if (visible) {
        return (
            <StyledFullScreenPage>
                <div>{text}</div>
            </StyledFullScreenPage>
        );
    }
    return null;
};

export default FullScreen;
