import { createSlice, PayloadAction, combineReducers } from '@reduxjs/toolkit';

import { AuthenticationError, queueLimit, serviceActive } from 'actions/api';

import * as auth from 'auth';
import { AppThunk } from 'reduxStore';

export interface Availability {
    name: string;
    active: boolean;
    delegate: boolean;
    source_active: boolean;
    source_disable: boolean;
}

export interface AvailabilityUpdate {
    skill: string;
    delegate?: boolean;
    active?: boolean;
}

export interface Limit {
    delegate: boolean;
    limit: number;
    current: number;
    delegatedLimit: number;
}

export interface LimitUpdate {
    delegate?: boolean;
    limit?: number;
}

const availability = createSlice({
    name: 'availability',
    initialState: [] as Availability[],
    reducers: {
        setAvailability(state, action: PayloadAction<Availability[]>) {
            return action.payload;
        },
    },
});

export const { setAvailability } = availability.actions;

const limit = createSlice({
    name: 'limit',
    initialState: {} as Limit,
    reducers: {
        setQueueLimit(state, action: PayloadAction<Limit>) {
            return action.payload;
        },
    },
});

export const { setQueueLimit } = limit.actions;

const backend = createSlice({
    name: 'backend',
    initialState: null as string | null,
    reducers: {
        setAdminBackend(state, action: PayloadAction<string | null>) {
            return action.payload;
        },
    },
});

export const { setAdminBackend } = backend.actions;

const authentication = createSlice({
    name: 'auth',
    initialState: false as boolean,
    reducers: {
        setAuthed(state, action: PayloadAction<boolean>) {
            return action.payload;
        },
    },
});

export const { setAuthed } = authentication.actions;

export default combineReducers({
    availability: availability.reducer,
    limit: limit.reducer,
    backend: backend.reducer,
    auth: authentication.reducer,
});

export const logout = (): AppThunk => async (dispatch) => {
    dispatch(setAuthed(false));
    await auth.logout();
};

export const updateAvailability =
    (available?: AvailabilityUpdate): AppThunk =>
    async (dispatch, getState) => {
        const backend = getState().admin.backend;
        let availability: Availability[];
        try {
            availability = await serviceActive(backend, available);
        } catch (err) {
            if (err instanceof AuthenticationError) {
                dispatch(logout());
                return;
            } else {
                throw err;
            }
        }
        dispatch(setAvailability(availability));
    };

export const updateQueueLimit =
    (limit?: LimitUpdate): AppThunk =>
    async (dispatch, getState) => {
        const backend = getState().admin.backend;
        let newLimit: Limit;
        try {
            newLimit = await queueLimit(backend, limit);
        } catch (err) {
            if (err instanceof AuthenticationError) {
                dispatch(logout);
                return;
            } else {
                throw err;
            }
        }
        dispatch(setQueueLimit(newLimit));
    };
