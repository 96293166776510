import React from 'react';
import PropTypes from 'prop-types';

import StyledStatusMessage from './styled';

const StatusMessage = ({ children }) => (
    <StyledStatusMessage>{children}</StyledStatusMessage>
);

StatusMessage.propTypes = {
    children: PropTypes.node.isRequired,
};

export default StatusMessage;
