import { push } from 'connected-react-router';
import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'reduxStore';

import { MessageType } from 'reducers/messages';
import { loadPreferences } from 'reducers/app';
import { attemptConnection, closeConnection } from 'reducers/connection';
import * as api from 'actions/api';

import * as networkAction from 'network/actions';

import { END_SESSION_URL } from 'chatConstants';
import {
    enteredChat,
    enteredChatFromReload,
    joinedQueue,
    attemptJoin,
    attemptJoinFromReload,
    joinedQueueFromReload,
    resetChat,
    exitedChat,
} from './actions';

const waitTime = createSlice({
    name: 'waitTime',
    initialState: {
        queue: null as number | null,
        landing: null as number | null,
    },
    reducers: {
        clearWaitTime: () => ({ queue: null, landing: null }),
        setQueueWait: (state, action: PayloadAction<number>) => {
            state.queue = action.payload;
        },
        setLandingWait: (state, action: PayloadAction<number>) => {
            state.landing = action.payload;
        },
    },
    extraReducers: {
        [resetChat.toString()]: () => ({ queue: null, landing: null }),
    },
});

export const { clearWaitTime, setQueueWait, setLandingWait } = waitTime.actions;

export enum Room {
    LANDING = 'LANDING',
    WAITING = 'WAITING',
    CHAT = 'CHAT',
    FEEDBACK = 'FEEDBACK',
}

const room = createSlice({
    name: 'room',
    initialState: Room.LANDING as Room,
    reducers: {
        setLandingRoom: () => Room.LANDING,
        setWaitingRoom: () => Room.WAITING,
        setChatRoom: () => Room.CHAT,
        setFeedbackRoom: () => Room.FEEDBACK,
        setRoom: (state, action: PayloadAction<Room>) => action.payload,
    },
    extraReducers: {
        [resetChat.toString()]: () => Room.LANDING,
        [attemptJoin.toString()]: () => Room.WAITING,
        [attemptJoinFromReload.toString()]: () => Room.WAITING,
        [joinedQueue.toString()]: () => Room.WAITING,
        [joinedQueueFromReload.toString()]: () => Room.WAITING,
        [enteredChat.toString()]: () => Room.CHAT,
        [enteredChatFromReload.toString()]: () => Room.CHAT,
    },
});

export const {
    setLandingRoom,
    setWaitingRoom,
    setFeedbackRoom,
    setChatRoom,
    setRoom,
} = room.actions;

export enum Status {
    LOADING = 'LOADING',
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    ERRORED = 'ERRORED',
    BARRED = 'BARRED',
    BUSY = 'BUSY',
}

const status = createSlice({
    name: 'status',
    initialState: Status.LOADING as Status,
    reducers: {
        setLoading: () => Status.LOADING,
        setOnline: () => Status.ONLINE,
        setOffline: () => Status.OFFLINE,
        setErrored: () => Status.ERRORED,
        setBusy: () => Status.BUSY,
        setBarred: () => Status.BARRED,
    },
    extraReducers: {
        [resetChat.toString()]: () => Status.ONLINE,
        [enteredChatFromReload.toString()]: () => Status.ONLINE,
        [setQueueWait.toString()]: () => Status.ONLINE,
    },
});

export const {
    setLoading,
    setOnline,
    setOffline,
    setErrored,
    setBarred,
    setBusy,
} = status.actions;

export enum State {
    PREPARING = 'PREPARING',
    READY = 'READY',
    JOINING = 'JOINING',
    QUEUED = 'QUEUED',
    STARTED = 'STARTED',
    ENDED = 'ENDED',
}

const appState = createSlice({
    name: 'state',
    initialState: State.PREPARING as State,
    reducers: {
        setPreparing: () => State.PREPARING,
        setReady: () => State.READY,
        setJoining: () => State.JOINING,
        setQueued: () => State.QUEUED,
        setStarted: () => State.STARTED,
        setEnded: () => State.ENDED,
    },
    extraReducers: {
        [resetChat.toString()]: () => State.READY,
        [joinedQueue.toString()]: () => State.QUEUED,
        [joinedQueueFromReload.toString()]: () => State.QUEUED,
        [attemptJoin.toString()]: () => State.JOINING,
        [enteredChat.toString()]: () => State.STARTED,
        [enteredChatFromReload.toString()]: () => State.STARTED,
    },
});

export const {
    setPreparing,
    setReady,
    setEnded,
    setQueued,
    setJoining,
    setStarted,
} = appState.actions;

const typing = createSlice({
    name: 'typing',
    initialState: false as boolean,
    reducers: {
        startTyping: () => true,
        stopTyping: () => false,
    },
});

export const { startTyping, stopTyping } = typing.actions;

const activity = createSlice({
    name: 'activity',
    initialState: true as boolean,
    reducers: {
        setActive: () => true,
        setInactive: () => false,
    },
});

export const { setInactive, setActive } = activity.actions;

const errorInitialState = {
    message: '' as string,
    visible: false as boolean,
};

const errors = createSlice({
    name: 'errors',
    initialState: errorInitialState,
    reducers: {
        clearError: () => errorInitialState,
        setErrorMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        },
        showError: (state) => {
            state.visible = true;
        },
    },
});

export const { clearError, setErrorMessage, showError } = errors.actions;

const dataInitialState = {
    id: null as string | null,
    preScore: 0 as number,
    postScore: 0 as number,
    volunteerPresent: false,
    returnedTo: null as Room | null,
};

const data = createSlice({
    name: 'data',
    initialState: dataInitialState,
    reducers: {
        setID: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        setPreScore: (state, action: PayloadAction<number>) => {
            state.preScore = action.payload;
        },
        setPostScore: (state, action: PayloadAction<number>) => {
            state.postScore = action.payload;
        },
        volunteerJoin: (state) => {
            state.volunteerPresent = true;
        },
        volunteerLeave: (state) => {
            state.volunteerPresent = false;
        },
    },
    extraReducers: {
        [joinedQueue.toString()]: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        [joinedQueueFromReload.toString()]: (
            state,
            action: PayloadAction<string>,
        ) => {
            state.id = action.payload;
            state.returnedTo = Room.WAITING;
        },
        [enteredChatFromReload.toString()]: (state) => {
            state.returnedTo = Room.CHAT;
        },
    },
});

export const {
    setID,
    setPreScore,
    setPostScore,
    volunteerLeave,
    volunteerJoin,
} = data.actions;

const skill = createSlice({
    name: 'skill',
    initialState: '' as string,
    reducers: {
        setSkill: (state, action: PayloadAction<string>) => action.payload,
    },
});

export const { setSkill } = skill.actions;

const backend = createSlice({
    name: 'backend',
    initialState: '' as string,
    reducers: {
        setBackend: (state, action: PayloadAction<string>) => action.payload,
    },
});

export const { setBackend } = backend.actions;

export default combineReducers({
    room: room.reducer,
    status: status.reducer,
    state: appState.reducer,
    waitTime: waitTime.reducer,
    data: data.reducer,
    typing: typing.reducer,
    errors: errors.reducer,
    skill: skill.reducer,
    backend: backend.reducer,
});

export const attemptJoinQueue = (): AppThunk => async (dispatch) => {
    dispatch(resetChat());
    dispatch(attemptConnection(true));
    dispatch(attemptJoin());
    dispatch(loadPreferences());
    dispatch(clearError());
};

export const startChat = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    dispatch(volunteerJoin());
    if (state.chat.status === Status.LOADING) {
        // We are initialising the app and the chat is active
        // move into active chat
        dispatch(enteredChatFromReload());
    } else if (
        state.chat.room === Room.CHAT &&
        state.chat.state !== State.STARTED
    ) {
        // If we are in the chat room and haven't previously started the chat we
        // want to tell the backend as we cannot trigger enteredChat with a
        // button
        dispatch(enteredChat());
    } else {
        dispatch(setStarted());
    }
};

export const joinedTheQueue =
    (chatID: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        if (state.chat.status === Status.LOADING) {
            dispatch(joinedQueueFromReload(chatID));
        } else if (state.chat.room !== Room.CHAT) {
            // If user is already in the chat room, do not join the queue.
            dispatch(joinedQueue(chatID));
        }
    };

export const getWaitTime = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const skill = state.chat.skill;
    const backend = state.chat.backend;

    let wait_time, active;
    try {
        ({ wait_time, active } = await api.fetchWaitTime(backend, skill));
    } catch {
        // If there are any problems fetching service status from the backend
        // we want to default to the service unavailable page
        dispatch(setOffline());
        return;
    }
    dispatch(active ? setOnline() : setOffline());
    dispatch(setLandingWait(wait_time));
};

export const exitChat =
    (destination: Room): AppThunk =>
    async (dispatch) => {
        // notify backend of exit
        dispatch(exitedChat());
        // Tidy up our state
        dispatch(resetChat());

        dispatch(setRoom(destination));
    };

export const endSession =
    (destination?: string): AppThunk =>
    async (dispatch) => {
        const getPushUrl = () => {
            if (destination) {
                const searchParams = new URLSearchParams();
                searchParams.set('redirect', destination);
                return `${END_SESSION_URL}?${searchParams.toString()}`;
            }
            return END_SESSION_URL;
        };
        dispatch(exitedChat());
        dispatch(resetChat());
        await dispatch(closeConnection());
        dispatch(push(getPushUrl()));
    };

export const handleStatus =
    (message: networkAction.ReceivedMessage): AppThunk =>
    async (dispatch) => {
        switch (message.type) {
            case MessageType.MESSAGE:
                dispatch(stopTyping());
                dispatch(clearError());
                break;
            case MessageType.FAILED:
                dispatch(setErrorMessage('Message failed to send'));
                dispatch(setErrored());
                break;
            case MessageType.START:
                dispatch(startChat());
                break;
            case MessageType.LEAVE:
                dispatch(setEnded());
                dispatch(volunteerLeave());
                break;
            case MessageType.TYPING:
                if (message.value) {
                    dispatch(startTyping());
                } else {
                    dispatch(stopTyping());
                }
                break;
            case MessageType.WAIT:
                dispatch(setQueueWait(message.value));
                break;
            case MessageType.ERROR:
                dispatch(setErrorMessage(message.value));
                break;
            case MessageType.JOIN:
                dispatch(joinedTheQueue(message.value));
                break;
        }
    };
