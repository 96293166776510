import React from 'react';
import Modal from 'react-modal';

import './styles.scss';

const SiteModal = ({ open, children }) => {
    return (
        <Modal
            overlayClassName="modal__overlay"
            className="modal__content"
            isOpen={open}
            ariaHideApp={false}
        >
            {children}
        </Modal>
    );
};

export default SiteModal;
