import { ONETRUST_ID } from 'chatConstants';

const oneTrustInit = () => {
    if (ONETRUST_ID) {
        OneTrust.initialize(ONETRUST_ID);
    }
};

const OneTrust = {
    dataScript: function (oneTrustId) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src =
            'https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js';
        script.charset = 'UTF-8';
        script.setAttribute('data-domain-script', oneTrustId);
        return script;
    },

    wrapperFunc: function () {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = 'function OptanonWrapper() { }';
        return script;
    },

    initialize: function (oneTrustId) {
        if (oneTrustId) {
            document.head.appendChild(this.dataScript(oneTrustId));
            document.head.appendChild(this.wrapperFunc());
        }
    },
};

export default oneTrustInit;
