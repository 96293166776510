import React, { ReactNode } from 'react';

import { timeDisplay } from '../../utils/wait-time';

import ClockIcon from '../ClockIcon';
import { Content, Panel, WaitTime } from './styled';

type Props = {
    waitTime: number | null;
    children?: ReactNode;
    beforeTime?: ReactNode;
} & typeof defaultProps;

const defaultProps = {
    paused: false,
};

const HorizontalWaitTimePanel = ({ waitTime }: Props) => {
    return (
        <Panel>
            <ClockIcon paused={false} large />
            <Content>
                <span
                    aria-live="polite"
                    aria-label={`There is currently a ${timeDisplay(
                        waitTime,
                    )} wait time.`}
                ></span>
                <p>Estimated time remaining</p>
                <WaitTime>{timeDisplay(waitTime)}</WaitTime>
            </Content>
        </Panel>
    );
};
HorizontalWaitTimePanel.defaultProps = defaultProps;

export default HorizontalWaitTimePanel;
