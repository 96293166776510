import { CloseEvent } from 'reconnecting-websocket';

import { EmmittedEvent } from 'network/middleware';
import { Client, Handlers } from 'client';
import { ReceivedMessage } from './actions';

interface NetworkManager {
    client: Client;
    boundSendHandler: EventListener;
    handlers: Handlers['connection'] | undefined;
}

/** A general network manager utility class that handles message sending and receiving through a supplied transport */
class NetworkManager {
    constructor(client: Client) {
        this.client = client;
        this.boundSendHandler = this.messageSendHandler.bind(
            this,
        ) as EventListener;
    }

    /**
     * Initialise the network transport and bind event handlers
     */
    async init(url: string, handlers: Handlers['connection']) {
        this.handlers = handlers;
        window.addEventListener(
            'network_listener_event',
            this.boundSendHandler,
        );
        this.client.init(
            {
                message: this.messageReceiveHandler.bind(this),
                connection: {
                    close: this.connectionCloseHandler.bind(this),
                    attempt: this.connectionAttemptHandler.bind(this),
                    open: this.connectionOpenHandler.bind(this),
                },
            },
            url,
        );
    }

    async close() {
        window.removeEventListener(
            'network_listener_event',
            this.boundSendHandler,
        );
        return this.client.close();
    }

    hasConnected() {
        return this.client.hasConnected();
    }

    messageReceiveHandler(message: ReceivedMessage) {
        if (this.handlers) {
            this.handlers.receive(message);
        } else {
            this.warnInit();
        }
    }

    warnInit() {
        console.error(
            'Handler does not exist. Either the network needs setting up with init() or the handler is missing from the handlers argument to init()',
        );
    }

    connectionOpenHandler() {
        if (this.handlers) {
            this.handlers.open();
        } else {
            this.warnInit();
        }
    }

    connectionCloseHandler(err: CloseEvent) {
        if (this.handlers) {
            this.handlers.close(err);
        } else {
            this.warnInit();
        }
    }

    connectionAttemptHandler() {
        if (this.handlers) {
            this.handlers.attempt();
        } else {
            this.warnInit();
        }
    }

    messageSendHandler({ detail: { payload, actionType } }: EmmittedEvent) {
        this.client.send(payload, actionType);
    }
}

export default NetworkManager;
