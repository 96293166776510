import styled from 'styled-components';
import device from 'styles/device';

const StyledBarWithIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 0.7rem 1rem;
    position: relative;

    @media ${device.mobLandscape} {
        padding: 1.2rem 1rem;
    }

    &::after {
        content: '';
        height: 1px;
        background-color: ${(props) => props.theme.colors.lightGrey};
        position: absolute;
        width: 95%;
        width: 95%;
        top: 0;
        left: 1rem;
    }

    svg {
        width: 40px;
        height: 40px;
        fill: ${(props) => props.theme.colors.lightPurple};
        margin-right: 1rem;
    }

    p {
        margin: 0;
        font-size: 0.8rem;

        &:first-child {
            font-weight: ${(props) => props.theme.fonts.bold};
        }
    }
`;

export default StyledBarWithIcon;
