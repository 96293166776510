import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-polyfill';

import 'custom-event-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import GlobalStyle from 'styles/global';

import App from './App';
import createStore, { history } from 'reduxStore';
import * as serviceWorker from './serviceWorker';
import SentryBoundary from './components/SentryBoundary';
import gaInit from 'ga.js';
import oneTrustInit from 'onetrust.js';
import { setOffline } from 'reducers/chat';

import './index.css';

serviceWorker.register();

gaInit();
oneTrustInit();

const store = createStore();

// If the config has failed to load from the backend, default to showing
// the service unavailable page
if (!(window as any).config) {
    store.dispatch(setOffline());
}

const render = (Component: React.ComponentClass) => {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                    <>
                        <GlobalStyle />
                        <SentryBoundary>
                            <Component />
                        </SentryBoundary>
                    </>
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root'),
    );
};

render(App);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}
