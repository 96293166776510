import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

const ButtonModal = ({ buttons, title, open }) => {
    return (
        <Modal open={open}>
            <div className="modal">
                <h3 className="modal__heading">{title}</h3>
                <div className="modal__buttons">{buttons}</div>
            </div>
        </Modal>
    );
};

ButtonModal.propTypes = {
    buttons: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

export default ButtonModal;
