import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as VolunteerAvatar } from 'assets/svgs/volunteer-avatar-alt.svg';
import StyledMessage from 'components/Message/styled';

const RemoteMessage = ({ children, modifier }) => (
    <StyledMessage data-hj-suppress className={`${modifier && modifier}`}>
        <VolunteerAvatar />
        {children}
    </StyledMessage>
);

RemoteMessage.propTypes = {
    children: PropTypes.node.isRequired,
};

export default RemoteMessage;
