import React from 'react';
import PropTypes from 'prop-types';

import ButtonModal from '../ButtonModal';
import Button from '../Button';

const ExitModal = ({ exitModalOpen, handleCancel, handleExit, room }) => {
    const context = room.toLowerCase();

    return (
        <ButtonModal
            open={exitModalOpen}
            title={`Are you sure you want to leave the ${context}?`}
            buttons={[
                <Button key="cancel" action={handleCancel}>
                    No, return to {context}
                </Button>,
                <Button key="accept" action={handleExit}>
                    Yes, leave {context}
                </Button>,
            ]}
        />
    );
};

ExitModal.propTypes = {
    exitModalOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
    room: PropTypes.string.isRequired,
};

ExitModal.defaultProps = {
    exitModalOpen: false,
};

export default ExitModal;
