import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from 'assets/svgs/warning.svg';
import BarWithIcon from 'components/BarWithIcon';

const ConnectionWarningBar = ({ moreInfo, serverError }) => {
    return (
        <BarWithIcon icon={<WarningIcon />}>
            <div>
                {moreInfo ? (
                    <>
                        <p>
                            There’s a problem with the service, and we’re still
                            having trouble connecting with you.
                        </p>
                        <p>
                            We know this might be frustrating. We’re doing
                            everything we can to fix the problem, but we don’t
                            know how long that might take.
                        </p>
                        <p>
                            Call us for free on{' '}
                            <a
                                aria-label="Samaritans phone number 116 123"
                                href="tel:116123"
                            >
                                116 123
                            </a>{' '}
                            to talk to a Samaritan, or{' '}
                            <a
                                aria-label="A link to other Samaritans services"
                                href={
                                    process.env.REACT_APP_SERVICES_DESTINIATION
                                }
                            >
                                find another service
                            </a>
                            .
                        </p>
                    </>
                ) : (
                    <>
                        {serverError ? (
                            <>
                                <p>We’re having trouble connecting with you.</p>
                                <p>
                                    If you prefer, you can call us for free on{' '}
                                    <a
                                        aria-label="Samaritans phone number 116 123"
                                        href="tel:116123"
                                    >
                                        116 123
                                    </a>{' '}
                                    to talk to a Samaritan.
                                </p>
                            </>
                        ) : (
                            <>
                                <p>
                                    It looks like you've lost your connection.
                                </p>
                                <p>
                                    We'll try to reconnect you for the next two
                                    minutes. If you can, try to get to somewhere
                                    with good internet access. Alternatively you
                                    can call us for free on{' '}
                                    <a
                                        aria-label="Samaritans phone number 116 123"
                                        href="tel:116123"
                                    >
                                        116 123
                                    </a>
                                    .
                                </p>
                            </>
                        )}
                    </>
                )}
            </div>
        </BarWithIcon>
    );
};

ConnectionWarningBar.propTypes = {
    moreInfo: PropTypes.bool,
};

export default ConnectionWarningBar;
