import React, { Component } from 'react';
import Toggle from 'react-toggle';

import { Panel, Heading } from './styled';

class FeedbackRoomDebug extends Component {
    render() {
        let { connected, error, updateError, connectionStatus } = this.props;

        let handleConnected = (event) => {
            connectionStatus(event.target.checked);
        };

        let handleError = (event) => {
            updateError(event.target.checked);
        };

        return (
            <Panel>
                <Heading>Connection Status</Heading>
                <Toggle defaultChecked={connected} onChange={handleConnected} />
                <Heading>Server error</Heading>
                <Toggle defaultChecked={!!error} onChange={handleError} />
            </Panel>
        );
    }
}

export default FeedbackRoomDebug;
