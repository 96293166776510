import React from 'react';

import Spinner from 'components/Spinner';

import StyledJoiningQueue from './styled';

const JoiningQueue = ({ moreInfo }) => (
    <StyledJoiningQueue>
        <div>
            <Spinner />
            <h3>Entering the waiting room...</h3>
            {moreInfo && (
                <>
                    <h4>
                        We are still trying to connect you, but it's taking
                        longer than expected.
                    </h4>
                    <p>
                        You can call us free any time, from any phone on{' '}
                        <a
                            aria-label="Samaritans phone number 116 123"
                            href="tel:116123"
                        >
                            116 123
                        </a>
                        .
                    </p>
                </>
            )}
        </div>
    </StyledJoiningQueue>
);

export default JoiningQueue;
