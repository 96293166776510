import React from 'react';

import { ReactComponent as WarningIcon } from 'assets/svgs/warning.svg';
import BarWithIcon from 'components/BarWithIcon';

const ChatEndedBar = () => {
    return (
        <BarWithIcon icon={<WarningIcon />}>
            <div>
                <p>
                    Unfortunately, your chat has ended because we couldn't reach
                    you
                </p>
                <p>
                    Maybe you lost signal or had problems with your Wifi
                    connection
                </p>
            </div>
        </BarWithIcon>
    );
};

export default ChatEndedBar;
