import { AppMiddleware } from 'middleware';
import { Message } from 'reducers/messages';

export type EmmittedEvent = CustomEvent<{
    payload: Message | string;
    actionType: string;
}>;

const eventEmitterMiddleware: AppMiddleware = () => (next) => (action) => {
    const event = new CustomEvent(`network_listener_event`, {
        detail: {
            payload: action.payload,
            actionType: action.type,
        },
    });

    window.dispatchEvent(event);
    return next(action);
};

export default eventEmitterMiddleware;
