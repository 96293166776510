import React, { Component } from 'react';
import Toggle from 'react-toggle';

import { Panel, Heading, Room } from './styled';

function toProperCase(string) {
    return string
        .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
        .replace('_', ' ');
}

class GeneralDebug extends Component {
    render() {
        let {
            rooms,
            currentRoom,
            changeRoom,
            chat,
            connection,
            serviceAvailable,
            updateServiceAvailability,
        } = this.props;

        let handleServiceAvailability = (event) => {
            updateServiceAvailability(event.target.checked);
        };

        return (
            <>
                <Panel>
                    <Heading>Rooms</Heading>
                    {rooms.map((room) => (
                        <Room key={room}>
                            <input
                                type="radio"
                                name="room"
                                value={room}
                                checked={currentRoom === room}
                                onChange={() => changeRoom(room)}
                            />
                            {toProperCase(room)}
                        </Room>
                    ))}
                </Panel>

                <Panel>
                    <h3>App availble</h3>
                    <Toggle
                        defaultChecked={serviceAvailable}
                        onChange={handleServiceAvailability}
                    />
                </Panel>

                <Panel>
                    <h3>Chat status</h3>
                    <p>
                        Connection status: <b>{toProperCase(connection)}</b>
                    </p>
                    <p>
                        ID: <b>{chat.id || 'No ID'}</b>
                    </p>
                </Panel>
            </>
        );
    }
}

export default GeneralDebug;
