import React from 'react';
import { connect } from 'react-redux';

import FullScreenPage from '../components/FullScreenPage';

const Redirecting = () => {
    return <FullScreenPage visible={true} text={'Redirecting'} />;
};

export default connect(null, null)(Redirecting);
