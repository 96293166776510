import React, { useEffect, useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import useInterval from '@rooks/use-interval';
import Slider, { Handle, HandleProps } from 'rc-slider';
import Toggle from 'react-toggle';
import Tooltip from 'rc-tooltip';

import {
    setAdminBackend,
    updateAvailability,
    updateQueueLimit,
    logout,
    Availability,
    AvailabilityUpdate,
    Limit,
    LimitUpdate,
} from 'reducers/admin';
import RoomWithoutSidePanel from 'components/RoomWithoutSidePanel/styled';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

import Panel, {
    SliderButton,
    SliderWrapper,
    SkillsHeadings,
    Skill,
} from './styled';
import { RootState } from 'reducers';

type Props = {
    backend?: string;
    backendName: string;
    setBackend: (backend: string | null) => void;
    updateAvailability: (update?: AvailabilityUpdate) => void;
    skills: Availability[];
    queueLimit: Limit;
    updateQueueLimit: (value?: LimitUpdate) => void;
    logout: () => void;
};

const pluralize = (singular: string, plural: string, count: number) => {
    if (count === 1) {
        return singular;
    } else {
        return plural;
    }
};

const Admin = ({
    backend,
    backendName,
    setBackend,
    updateAvailability,
    skills,
    queueLimit,
    updateQueueLimit,
    logout,
}: Props) => {
    const [queueValue, updateQueueValue] = useState(queueLimit.limit);

    useEffect(() => {
        setBackend(backend ? backend : null);
    }, [backend, setBackend]);

    const backendDisplay =
        backendName.charAt(0).toUpperCase() + backendName.slice(1);

    useEffect(() => {
        updateQueueLimit();
        updateAvailability();
    }, [updateAvailability, updateQueueLimit, backend]);

    useEffect(() => {
        updateQueueValue(queueLimit.limit);
    }, [queueLimit, updateQueueValue]);

    const { start, stop } = useInterval(
        () => {
            updateQueueLimit();
            updateAvailability();
        },
        5000,
        true,
    );

    // restart the interval in an attempt to avoid race conditions
    const sendAvaliability = (data: AvailabilityUpdate) => {
        stop();
        updateAvailability(data);
        start();
    };

    const sendQueueLimit = (data: LimitUpdate) => {
        stop();
        updateQueueLimit(data);
        start();
    };

    const serviceStatus = (skill: Availability) => {
        let open;
        let controller;
        if (skill.delegate) {
            open = skill.source_active;
            controller = backendDisplay;
        } else {
            open = skill.active;
            controller = 'Overridden locally';
        }
        return (open ? 'Open' : 'Closed') + ' because: ' + controller;
    };
    const minQueue = 0;
    const maxQueue = 100;
    const marks = {
        [minQueue]: minQueue.toString(),
        [maxQueue / 2]: (maxQueue / 2).toString(),
        [maxQueue]: maxQueue.toString(),
    };

    return (
        <RoomWithoutSidePanel>
            <h1>Webchat {backendDisplay} admin</h1>
            <Panel>
                <h2>App status</h2>
                <SkillsHeadings>
                    <div></div>
                    <h4>{backendDisplay} controlled</h4>
                    <h4>{backendDisplay} status</h4>
                    <h4>Local Status</h4>
                </SkillsHeadings>
                <div>
                    {skills?.map((skill) => (
                        <Skill key={skill.name}>
                            <div>
                                <div>
                                    <h3>{skill.name}</h3>
                                    <p>{serviceStatus(skill)}</p>
                                </div>

                                {skill.source_disable ? (
                                    <div>Communication Error</div>
                                ) : (
                                    <Checkbox
                                        id={skill.name + '-checkbox'}
                                        checked={skill.delegate}
                                        onChange={(event) =>
                                            sendAvaliability({
                                                skill: skill.name,
                                                delegate: event.target.checked,
                                            })
                                        }
                                        ariaLabel={`Is the ${skill.name} skill open`}
                                    />
                                )}

                                <span>
                                    {skill.source_active ? 'Open' : 'Closed'}
                                </span>

                                <Toggle
                                    disabled={
                                        skill.delegate || skill.source_disable
                                    }
                                    checked={
                                        skill.active && !skill.source_disable
                                    }
                                    onChange={(
                                        event: ChangeEvent<{
                                            checked: boolean;
                                        }>,
                                    ) =>
                                        sendAvaliability({
                                            skill: skill.name,
                                            active: event.target.checked,
                                        })
                                    }
                                />
                            </div>
                        </Skill>
                    ))}
                </div>
            </Panel>
            <Panel>
                <h2>Queue Limit</h2>
                <div>
                    <p>
                        Controlled by{' '}
                        {queueLimit.delegate ? backendDisplay : 'Local'},
                        limited to{' '}
                        {queueLimit.delegate
                            ? queueLimit.delegatedLimit
                            : queueLimit.limit}
                    </p>
                    <p>
                        Currently {queueLimit.current}{' '}
                        {pluralize('person', 'people', queueLimit.current)} in
                        queue
                    </p>
                </div>
                <div>
                    <h4>Contol locally?</h4>
                    <Toggle
                        checked={!queueLimit.delegate}
                        onChange={(event: ChangeEvent<{ checked: boolean }>) =>
                            sendQueueLimit({
                                delegate: !event.target.checked,
                            })
                        }
                    />
                </div>
                <SliderWrapper>
                    <SliderButton
                        onClick={() =>
                            sendQueueLimit({ limit: queueLimit.limit - 1 })
                        }
                        disabled={queueLimit.delegate}
                    >
                        -
                    </SliderButton>
                    <Slider
                        disabled={queueLimit.delegate}
                        min={minQueue}
                        max={maxQueue}
                        marks={marks}
                        value={queueValue}
                        onChange={updateQueueValue}
                        onAfterChange={(value) =>
                            sendQueueLimit({ limit: value })
                        }
                        handle={handle}
                    />
                    <SliderButton
                        onClick={() =>
                            sendQueueLimit({ limit: queueLimit.limit + 1 })
                        }
                        disabled={queueLimit.delegate}
                    >
                        +
                    </SliderButton>
                </SliderWrapper>
            </Panel>
            <Button action={logout}>Logout</Button>
        </RoomWithoutSidePanel>
    );
};

// Types not up to date - add in extra props
type NewHandle = React.ComponentClass<HandleProps & { value: number }>;
const handle = (
    props: HandleProps & { dragging: boolean; value: number; index: number },
) => {
    const { value, dragging, index, ...restProps } = props;
    const HandleComponent = Handle as unknown as NewHandle;
    return (
        <Tooltip
            visible={true}
            overlay={value}
            placement="top"
            prefixCls="rc-slider-tooltip"
        >
            <HandleComponent value={value} {...restProps} />
        </Tooltip>
    );
};

const mapStateToProps = (state: RootState) => ({
    skills: state.admin.availability,
    queueLimit: state.admin.limit,
});
const mapDispatchToProps = {
    setBackend: setAdminBackend,
    updateAvailability,
    updateQueueLimit,
    logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
