import React, { useState, useCallback, useEffect, useRef } from 'react';
import autosize from 'autosize';

import { throttle } from 'throttle-debounce';
import useInterval from '@rooks/use-interval';

import {
    STOPPED_TYPING_LIMIT,
    INACTIVITY_NOTIFICATION_LIMIT,
} from 'chatConstants';

import StyledMessageInput from './styled';

const MESSAGE_CHARACTER_LIMIT = 5000;

type Props = {
    onSubmit: (message: string) => void;
    typingStatus: (status: boolean) => void;
    inactive: (status: boolean, duration: number) => void;
    disabled: boolean;
};

const now = () => new Date().getTime();

const Input = ({ onSubmit, typingStatus, inactive, disabled }: Props) => {
    const [message, setMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [isInactive, setInactive] = useState(false);
    const [lastActivity, setLastActivity] = useState(now());
    const textArea = useRef<HTMLTextAreaElement>(null);

    const checkInactive = () => {
        const shouldNotify =
            now() - lastActivity > INACTIVITY_NOTIFICATION_LIMIT;
        if (shouldNotify && !isInactive) {
            inactive(true, INACTIVITY_NOTIFICATION_LIMIT);
            setInactive(true);
        }
    };

    useInterval(() => checkInactive(), 1000, true);

    const throttleTyping = useCallback(throttle(1500, true, typingStatus), [
        typingStatus,
    ]);

    const checkTyping = () => {
        if (now() - lastActivity > STOPPED_TYPING_LIMIT && isTyping) {
            setIsTyping(false);
            typingStatus(false);
        }
    };

    useInterval(() => checkTyping(), 200, true);

    useEffect(() => {
        if (textArea && textArea.current) {
            autosize(textArea.current);
        }
    }, []);

    useEffect(() => {
        if (textArea && textArea.current) {
            autosize.update(textArea.current);
        }
    }, [message, textArea]);

    const handleActivity = () => {
        setLastActivity(now());
        if (isInactive) {
            inactive(false, 0);
            setInactive(false);
        }
    };

    const handleSubmit = () => {
        if (!message || !message.trim()) {
            return;
        }
        typingStatus(false);
        onSubmit(message);
        setMessage('');
        handleActivity();
        if (textArea && textArea.current) {
            textArea.current.focus();
        }
    };

    const handleEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        handleSubmit();
    };

    const handleTyping = () => {
        handleActivity();
        setIsTyping(true);
        throttleTyping(true);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setMessage(value.slice(0, MESSAGE_CHARACTER_LIMIT));
    };

    return (
        <StyledMessageInput>
            <div>
                <textarea
                    data-hj-suppress
                    ref={textArea}
                    placeholder={
                        disabled
                            ? "You can't type or add more messages to this chat"
                            : 'Your message'
                    }
                    aria-label="Message input"
                    value={message || ''}
                    onChange={handleOnChange}
                    onKeyDown={(event) => {
                        event.keyCode === 13 && handleEnter(event);
                    }}
                    onKeyUp={(event) => {
                        event.keyCode !== 13 && handleTyping();
                    }}
                    disabled={disabled}
                />
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                    disabled={disabled}
                >
                    Send
                </button>
            </div>
        </StyledMessageInput>
    );
};

export default Input;
