import { connect } from 'react-redux';

import ChatRoom from 'components/ChatRoom';
import {
    attemptJoinQueue,
    exitChat,
    endSession,
    Room,
    State,
    Status,
} from 'reducers/chat';
import {
    enableAudio,
    disableAudio,
    enableNotifications,
    disableNotifications,
} from 'reducers/app';

import { RootState } from 'reducers';
import { ConnectionStatus } from 'reducers/connection';
import { selectOrderedMessages, retryMessage } from 'reducers/messages';

import { AppDispatch } from 'reduxStore';

const mapStateToProps = (state: RootState) => ({
    connected: state.connection.status === ConnectionStatus.ESTABLISHED,
    serverError: state.chat.status === Status.ERRORED,
    ended: state.chat.state === State.ENDED,
    started: state.chat.state === State.STARTED,
    notifications: state.app.notifications,
    audio: state.app.audio,
    volunteerExit:
        state.chat.state === State.ENDED && !state.chat.data.volunteerPresent,
    messages: selectOrderedMessages(state),
    typing: state.chat.typing,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    messageRetry: (messageID: string) => dispatch(retryMessage(messageID)),
    handleExit: (started: boolean) =>
        started
            ? dispatch(exitChat(Room.FEEDBACK))
            : dispatch(endSession(undefined)),
    joinAction: () => dispatch(attemptJoinQueue()),
    updateNotifications: (value: boolean) =>
        dispatch(value ? enableNotifications() : disableNotifications()),
    updateAudio: (value: boolean) =>
        dispatch(value ? enableAudio() : disableAudio()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);
