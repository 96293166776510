import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import useWindowSize from '@rehooks/window-size';

export const useIsMobile = () => {
    const theme = useContext(ThemeContext);
    const windowSize = useWindowSize();

    let siteWidth = parseInt(theme.grid.siteWidth, 10);

    if (isNaN(siteWidth)) {
        siteWidth = 1100; // Fallback to 1100px if not set
    }

    return windowSize.innerWidth < siteWidth;
};
