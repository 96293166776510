import ReconnectingWebSocket, { CloseEvent } from 'reconnecting-websocket';
import store from 'store';

import { ReceivedMessage } from 'network/actions';

import {
    makeMessage,
    Message,
    MessageType,
    isMessage,
} from 'reducers/messages';
import { RESEND_RETRY_LIMIT } from 'chatConstants';
import { sleep } from 'utils/sleep';
import * as actions from 'reducers/actions';
import { clearFingerprint, getFingerprint } from 'fingerprint';

export interface Handlers {
    message: (message: ReceivedMessage) => void;
    connection: {
        open: () => void;
        close: (error: CloseEvent) => void;
        attempt: () => void;
        receive: (message: ReceivedMessage) => void;
    };
}

export interface Client {
    init: Function;
    close: { (): void };
    hasConnected: { (): Boolean };
    send: { (payload: Message | string, actionType: string): void };
}

// Map the app actions to the backend actions
const typeMapping = {
    [actions.sendMessage.toString()]: 'MESSAGE',
    [actions.typing.toString()]: 'TYPING',
    [actions.enteredChat.toString()]: 'ENTER',
    [actions.exitedChat.toString()]: 'EXIT',
    [actions.sendFeedback.toString()]: 'FEEDBACK',
    [actions.inactivity.toString()]: 'INACTIVE',
    JOIN: 'JOIN',
    WAIT: 'WAIT',
    PING: 'PING',
    ACKNOWLEDGE: 'ACKNOWLEDGE',
};

const storageChatID = () => {
    return 'chatID_' + window.location.href;
};

const clearStorage = () => {
    clearFingerprint();
    store.remove(storageChatID());
};

const RETRY_INTERVAL_TIMEOUT = 2000;

class websocketClient implements Client {
    positionPoll: undefined | number = undefined;
    typingTimeout: undefined | number = undefined;
    heartbeat: undefined | number = undefined;
    retryPoll: undefined | number = undefined;
    messages: { [id: string]: Message } = {};
    attempts: { [id: string]: number } = {};
    chatID: string | null = null;
    handlers: Handlers | null = null;
    chatSocket: null | ReconnectingWebSocket = null;

    init(handlers: Handlers, url: string) {
        this.chatID = store.get(storageChatID());
        this.handlers = handlers;
        if (
            !this.chatSocket ||
            this.chatSocket.readyState === this.chatSocket.CLOSED
        ) {
            return new Promise((resolve, reject) => {
                this.chatSocket = new ReconnectingWebSocket(url, [], {
                    minReconnectionDelay: 0,
                });
                this.chatSocket.onopen = () => {
                    this.handlers?.connection.open();
                    getFingerprint().then((fingerprint) => {
                        this.send(
                            makeMessage({
                                value: fingerprint as string,
                            }),
                            'JOIN',
                        );
                    });
                };
                this.chatSocket.onmessage = (message) => {
                    this.onmessage(message);
                };
                this.chatSocket.onclose = (err) => {
                    this.onclose(err);
                };
                resolve(undefined);
            });
        } else {
            return new Promise((resolve) => {
                resolve(undefined);
            });
        }
    }

    hasConnected() {
        const openSocket =
            this.chatSocket &&
            this.chatSocket.readyState !== this.chatSocket.CLOSED;
        const previousConnection = store.get(storageChatID());
        return openSocket || previousConnection;
    }

    async close() {
        this.chatSocket && this.chatSocket.close(1000);
        while (
            this.chatSocket &&
            this.chatSocket.readyState === this.chatSocket.CLOSING
        ) {
            await sleep(100);
        }
        clearStorage();
        clearInterval(this.positionPoll);
        clearInterval(this.heartbeat);
    }

    async send(value: Message | string, action: string) {
        const type = typeMapping[action];

        // We dont handle this action
        if (!type) {
            return;
        }
        const baseData = {
            type: type,
            chatID: this.chatID,
        };

        let data;
        if (isMessage(value)) {
            data = { ...baseData, ...makeMessage(value) };
        } else {
            data = { ...baseData, ...makeMessage({ value }) };
        }

        if (action === actions.sendMessage.toString()) {
            this.messages[data.id] = data;
            this.attempts[data.id] = 1;
        }

        var payload = JSON.stringify(data);

        return new Promise((resolve) => {
            this.chatSocket?.send(payload);
            resolve(undefined);
        });
    }

    retry() {
        for (var messageID in this.messages) {
            if (this.attempts[messageID] > RESEND_RETRY_LIMIT) {
                delete this.attempts[messageID];
                const failedMessage = {
                    type: MessageType.FAILED,
                    ...makeMessage({ value: messageID }),
                } as ReceivedMessage; // pretend we are received - only difference is the addition of status
                this.handlers?.message(failedMessage);
            } else {
                let message = this.messages[messageID];
                this.send(message, actions.sendMessage.toString());
                this.attempts[messageID] += 1;
            }
        }
    }

    onmessage(message: MessageEvent) {
        var data = JSON.parse(message.data);
        var { type, value } = data;
        if (type === 'PONG') {
            this.respondToPong(value);
            return;
        }
        if (type === 'JOIN') {
            this.chatID = value;
            this.ping();
            store.set(storageChatID(), value);
            this.checkPosition();
            this.positionPoll = setInterval(
                this.checkPosition.bind(this),
                30000,
            );
        } else if (type === 'START') {
            clearInterval(this.positionPoll);
            // Clear the retry poll poll interval.
            clearInterval(this.retryPoll);
            // Set the retry interval with a delay in case the other one is still
            // in progress somehow.
            // This will avoid sending duplicate messages.
            setTimeout(() => {
                this.retryPoll = setInterval(
                    this.retry.bind(this),
                    RETRY_INTERVAL_TIMEOUT,
                );
            }, RETRY_INTERVAL_TIMEOUT * 2);
        } else if (type === 'MESSAGE') {
            this.send(data.id, 'ACKNOWLEDGE');
            clearInterval(this.positionPoll);
        } else if (type === 'TYPING') {
            clearTimeout(this.typingTimeout);
            if (data.value === true) {
                this.typingTimeout = setTimeout(() => {
                    data.value = false;
                    this.handlers?.message(data);
                }, 5000);
            }
        } else if (type === 'ACKNOWLEDGE') {
            delete this.messages[value];
        }

        this.handlers?.message(data);
    }

    ping() {
        this.send(makeMessage({ value: null }), 'PING');
        this.heartbeat = undefined;
    }

    respondToPong(wait: number) {
        if (!this.heartbeat) {
            this.heartbeat = setTimeout(this.ping.bind(this), wait);
        }
    }

    checkPosition() {
        return this.send(makeMessage({ value: null }), 'WAIT');
    }

    onclose(err: CloseEvent) {
        if (err.code === 4001) {
            clearStorage();
        }
        if (err.code >= 4000) {
            // Prevent reconnecting socket from reconnecting
            this.chatSocket?.close();
        }
        this.handlers?.connection.close(err);
        clearInterval(this.positionPoll);
        clearInterval(this.retryPoll);
        clearInterval(this.heartbeat);
    }
}
export default websocketClient;
