import { gaEvent, gaDataLayer } from 'ga.js';
import { timeDisplay } from 'utils/wait-time';

import * as actions from 'reducers/actions';
import * as chat from 'reducers/chat';
import { addMessage, MessageOrigin } from 'reducers/messages';
import { PAGE_UNLOAD } from 'actionTypes';
import { AppMiddleware } from 'middleware';

var sentWaitTime = false;
var sentFirstMessage = false;
var currentRoom = null as chat.Room | null;

const googleAnalytics: AppMiddleware = (store) => (next) => (action) => {
    // handle events differently if loading
    const state = store.getState();
    if (state.chat.status !== chat.Status.LOADING) {
        switch (action.type) {
            case chat.setLandingRoom.toString():
            case chat.setWaitingRoom.toString():
            case chat.setChatRoom.toString():
            case chat.setFeedbackRoom.toString():
            case chat.setRoom.toString():
                if (currentRoom !== action.room) {
                    gaDataLayer(action.room, state.chat.data.id, {
                        volunteerPresent: state.chat.data.volunteerPresent,
                    });
                    currentRoom = action.room;
                }
                break;
            case chat.setStarted.toString():
                gaDataLayer(state.chat.room, state.chat.data.id, {
                    volunteerPresent: true,
                });
                break;
            case chat.setQueueWait.toString():
                if (
                    !sentWaitTime &&
                    state.chat.room === chat.Room.WAITING &&
                    Number.isInteger(action.value)
                ) {
                    sentWaitTime = true;
                    gaEvent('webchat', { waitTime: timeDisplay(action.value) });
                }
                break;
            case addMessage.toString():
                if (
                    !sentFirstMessage &&
                    action.payload.origin === MessageOrigin.CLIENT
                ) {
                    sentFirstMessage = true;
                    gaEvent('firstMessage');
                }
                break;
            case PAGE_UNLOAD:
                gaEvent('chatEnds', { chatEndType: 'caller closing tab' });
                break;
            case actions.sendFeedback.toString():
                gaEvent('feedbackBefore', {
                    distressBefore: state.chat.data.preScore,
                });
                gaEvent('feedbackAfter', {
                    distressAfter: state.chat.data.postScore,
                });
                break;
            case actions.exitedChat.toString():
                if (
                    state.chat.state === chat.State.STARTED &&
                    state.chat.room === chat.Room.CHAT
                ) {
                    gaEvent('chatEnds', { chatEndType: 'caller exit' });
                }
                break;
            case chat.volunteerLeave.toString():
                gaEvent('chatEnds', { chatEndType: 'volunteer exit' });
                break;
            case chat.setOffline.toString():
                gaEvent('serviceUnavailable', { page: state.chat.room });
                break;
            case chat.setBusy.toString():
                gaEvent('serviceBusy', { page: state.chat.room });
                break;

            default:
                break;
        }
    } else {
        switch (action.type) {
            case chat.setChatRoom.toString():
                if (action.value === chat.Room.CHAT) {
                    // Assume that the user has previously sent a message if they were in the chat
                    sentFirstMessage = true;
                }
                break;
            default:
                break;
        }
    }

    return next(action);
};

export default googleAnalytics;
