import React from 'react';

import BarredPanel from './styled';
import { ReactComponent as VolunteerReadyAvatar } from 'assets/svgs/volunteer-ready.svg';

const Barred = () => (
    <BarredPanel>
        <VolunteerReadyAvatar />
        <h2>
            You are unable to access this service from this device as we are
            concerned about how it appears to have been used in the past.
        </h2>
        <p>We would like to talk to you about how best to support you.</p>
        <p>
            Please call: <a href="tel:03003032595">03003 032 595</a>
        </p>
        <p>
            or Email:{' '}
            <a href="mailto:caller.support@samaritans.org">
                caller.support@samaritans.org
            </a>
        </p>
    </BarredPanel>
);

export default Barred;
