import styled from 'styled-components';
import WhiteRoomCoverPanel from 'components/RoomCoverPanels/White/styled';

const BarredPanel = styled(WhiteRoomCoverPanel)`
    svg {
        fill: ${(props) => props.theme.colors.lightPurple};
    }
`;

export default BarredPanel;
