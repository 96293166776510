import React, { useState, FormEvent } from 'react';
import { connect } from 'react-redux';
import { History, Location } from 'history';

import { setAuthed } from 'reducers/admin';
import { getToken, processLogin, ErrorState, isToken } from 'auth';

import RoomWithoutSidePanel from 'components/RoomWithoutSidePanel/styled';
import StyledButton from 'components/Button/styled';

type Props = {
    login: () => void;
    history: History;
    location: Location;
};

const Login = ({ login, history, location }: Props) => {
    const [username, updateUsername] = useState('');
    const [password, updatePassword] = useState('');
    const [errored, setErrored] = useState({} as ErrorState);

    const { from } = location.state;
    const redirect = () => history.push(from.pathname || '/');

    const token = getToken();

    if (token) {
        login();
        redirect();
    }

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const token = await processLogin({ username, password });
        if (isToken(token)) {
            login();
            redirect();
        } else {
            setErrored(token);
        }
    };

    return (
        <RoomWithoutSidePanel>
            <form onSubmit={handleLogin}>
                <h4>Sign in</h4>
                <p>{errored.detail}</p>
                <label htmlFor="username">Username</label>
                <div>
                    <input
                        type="text"
                        name="username"
                        onChange={(e) => updateUsername(e.target.value)}
                    />
                    <p>{errored.username}</p>
                </div>
                <label htmlFor="password">Password</label>
                <div>
                    <input
                        type="password"
                        name="password"
                        onChange={(e) => updatePassword(e.target.value)}
                    />
                    <p>{errored.password}</p>
                </div>
                <div>
                    <StyledButton>Submit</StyledButton>
                </div>
            </form>
        </RoomWithoutSidePanel>
    );
};

const mapDispatchToProps = {
    login: () => setAuthed(true),
};

export default connect(null, mapDispatchToProps)(Login);
