import React from 'react';
import { connect } from 'react-redux';

import ConnectionBar from '../../components/ConnectionBar';
import { ConnectionStatus } from 'reducers/connection';
import { timeDisplay } from '../../utils/wait-time';
import { ReactComponent as VolunteerAvatar } from 'assets/svgs/volunteer-avatar.svg';
import { State, Status } from 'reducers/chat';
import { RootState } from 'reducers';

type Props = {
    connected: boolean;
    serverError: boolean;
    time: number | null;
    started: boolean;
    ended: boolean;
    navigatorOnline: boolean;
};

export const CallerConnectionBar = ({
    connected,
    serverError,
    time,
    started,
    ended,
    navigatorOnline,
}: Props) => {
    let heading = `Talking to a Samaritan`;
    let description = `Connected`;
    let offline =
        !started || !connected || serverError || ended || !navigatorOnline;

    if (!started) {
        description = `Waiting for a Samaritan`;
        heading = `${timeDisplay(time)} estimated wait`;
    } else if (!connected || serverError || !navigatorOnline) {
        description = 'Trying to connect';
    }

    if (ended) {
        heading = 'Session ended';
        if (!connected) {
            description = 'Connection lost';
        } else {
            description = 'Your chat has finished';
        }
    }

    return (
        <ConnectionBar
            offline={offline}
            avatar={<VolunteerAvatar />}
            description={description}
            heading={heading}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    connected: state.connection.status === ConnectionStatus.ESTABLISHED,
    serverError: state.chat.status === Status.ERRORED,
    time: state.chat.waitTime.queue,
    started: state.chat.state === State.STARTED,
    ended: state.chat.state === State.ENDED,
});

export default connect(mapStateToProps)(CallerConnectionBar);
