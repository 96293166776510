import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter, Redirect, RouteChildrenProps } from 'react-router';

import ChatApp from 'containers/ChatApp';
import CallerChatApp from 'pages/CallerChatApp';
import VolunteerApp from 'pages/VolunteerApp';

import Login from 'pages/Login';
import Redirecting from './containers/Redirecting';
import PrivateRoute from './containers/PrivateRoute';
import Admin from 'components/Admin';
import GeoBlocked from 'components/GeoBlocked';

import { Settings } from 'chatConstants';
import {
    CHAT_FRONTENDS,
    BASE_WEBSOCKET,
    END_SESSION_URL,
    VOLUNTEER_URL,
} from 'chatConstants';

const ALLOWED_EXTERNAL_REDIRECT_HOSTNAMES =
    process.env.REACT_APP_ALLOWED_EXTERNAL_REDIRECT_HOSTNAMES?.split(',') || [];

interface ExtraRoutesProps {
    path: string;
    url: string;
    settings: Settings;
}

const chatApps = [
    {
        name: 'mirror',
        extraRoutes: ({ path, url, settings }: ExtraRoutesProps) => (
            <Route
                path={path + VOLUNTEER_URL}
                render={(props) => (
                    <ChatApp
                        url={url + 'volunteer/'}
                        backend="mirror"
                        skill=""
                        settings={settings}
                    >
                        <VolunteerApp {...props} />
                    </ChatApp>
                )}
            />
        ),
    },
];

export const App = () => {
    const enabledBackends = Array.from(
        CHAT_FRONTENDS.reduce((backends, { backend }) => {
            backends.add(backend);
            return backends;
        }, new Set()),
    ) as string[];

    const AdminRoutes = [
        <Route exact path="/panel/login/" component={Login} key="adminLogin" />,
        // Grab the frontend name to get a tidy name for the default backend
        <PrivateRoute
            exact
            path="/panel/"
            key="defaultAdmin"
            render={() => {
                return <Admin backendName={CHAT_FRONTENDS[0]?.frontend} />;
            }}
        />,
        ...enabledBackends.map((backend) => {
            return (
                <PrivateRoute
                    exact
                    path={`/${backend}/panel/`}
                    key={`${backend}Admin`}
                    render={() => {
                        return (
                            <Admin backend={backend} backendName={backend} />
                        );
                    }}
                />
            );
        }),
    ];

    const ChatRoutes = CHAT_FRONTENDS.map(
        ({ path, frontend, backend, skill, websocket, settings }) => {
            const key = `${frontend}_${skill ? skill : 'default'}_${path}`;
            const url = `${BASE_WEBSOCKET}/${websocket}`;
            const { extraRoutes } =
                chatApps.find((app) => app.name === frontend) || {};
            return [
                <Route
                    exact={true}
                    path={path}
                    key={key}
                    render={() => {
                        return (
                            <ChatApp
                                url={url}
                                skill={skill}
                                backend={backend}
                                settings={settings}
                            >
                                <CallerChatApp />
                            </ChatApp>
                        );
                    }}
                />,
                extraRoutes && extraRoutes({ path, url, settings }),
            ];
        },
    );

    return (
        <Switch>
            <Route
                exact
                strict
                path="/:url*"
                render={(props) => (
                    <Redirect to={`${props.location.pathname}/`} />
                )}
            />

            <Route
                exact
                path={END_SESSION_URL}
                component={({ history, location }: RouteChildrenProps) => {
                    let destination;

                    const getFallbackUrl = () => {
                        if (!process.env.REACT_APP_POST_CHAT_DESTINATION) {
                            console.warn(
                                "REACT_APP_POST_CHAT_DESTINATION not set redirecting to '/'",
                            );
                            return '/';
                        } else {
                            return process.env.REACT_APP_POST_CHAT_DESTINATION;
                        }
                    };

                    if (location) {
                        const params = new URLSearchParams(location.search);
                        destination = params.get('redirect');
                    }
                    if (!destination) {
                        destination = getFallbackUrl();
                    }
                    try {
                        // If it's external URL, make sure it's HTTPS and the URL
                        // is allowed - to avoid people crafting URLs that
                        // redirect to non-legit sites or execute JavaScript.
                        const destinationUrl = new URL(destination);
                        if (
                            ALLOWED_EXTERNAL_REDIRECT_HOSTNAMES.includes(
                                destinationUrl.hostname,
                            ) &&
                            destinationUrl.protocol === 'https:'
                        ) {
                            window.location.href = destinationUrl.href;
                        } else {
                            history.push(getFallbackUrl());
                        }
                    } catch (error) {
                        history.push(destination);
                    }
                    return <Redirecting />;
                }}
            />

            {AdminRoutes}

            <Route exact path="/geo-blocked/" render={() => <GeoBlocked />} />

            {ChatRoutes}

            <Redirect to="/" />
        </Switch>
    );
};

export default withRouter(App);
