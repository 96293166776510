import React from 'react';
import PropTypes from 'prop-types';

import StyledConnectionBar from './styled';

const ConnectionBar = ({ avatar, description, heading, offline }) => {
    return (
        <StyledConnectionBar offline={offline}>
            {avatar}
            <div aria-live="polite">
                <h2>{heading}</h2>
                <p>{description}</p>
            </div>
        </StyledConnectionBar>
    );
};

ConnectionBar.propTypes = {
    avatar: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
};

export default ConnectionBar;
