import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from 'actions/api';
import { AppThunk } from 'reduxStore';

const queue = createSlice({
    name: 'connection',
    initialState: 0 as number,
    reducers: {
        setQueueLength: (state, action: PayloadAction<number>) => {
            return action.payload;
        },
    },
});

export const { setQueueLength } = queue.actions;

export default combineReducers({
    queue: queue.reducer,
});

export const getVolunteerQueue = (): AppThunk => async (dispatch) => {
    const length = await api.updateConnectionQueue();
    dispatch(setQueueLength(length));
};

export const incrementConnectionQueue =
    (amount: number): AppThunk =>
    async (dispatch) => {
        const length = await api.incrementConnectionQueue(amount);
        dispatch(setQueueLength(length));
    };
