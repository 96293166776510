import {
    QUEUE_ADD_URL,
    QUEUE_LENGTH_URL,
    QUEUE_LIMIT_URL,
    SERVICE_ACTIVE_URL,
    STATUS_URL,
} from 'chatConstants';
import { getToken, processRefresh } from 'auth';
import {
    AvailabilityUpdate,
    Availability,
    LimitUpdate,
    Limit,
} from 'reducers/admin';

interface AuthenticationError {
    message: string;
    toString: () => string;
}
export const AuthenticationError = function (this: AuthenticationError) {
    this.message = 'could not authenticate';
    this.toString = function (): string {
        return this.message;
    };
    return this;
} as any as { new (): AuthenticationError };

export const updateConnectionQueue = async (): Promise<number> => {
    const res = await fetch(QUEUE_LENGTH_URL);
    const { length } = await res.json();
    return length;
};

export const incrementConnectionQueue = async (amount = 1): Promise<number> => {
    await fetch(QUEUE_ADD_URL + `?n=` + amount, { method: 'POST' });
    return await updateConnectionQueue();
};

const getURL = async (url: string): Promise<Response> => {
    const token = getToken()?.access;
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    if (response.status === 401) {
        const newtoken = await processRefresh();
        if (!newtoken) {
            throw new AuthenticationError();
        }
        return await getURL(url);
    }
    return response;
};

const postData = async (url: string, data: object): Promise<Response> => {
    const token = getToken()?.access;
    const response = await fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        referrer: 'no-referrer',
        body: JSON.stringify(data),
    });
    if (response.status === 401) {
        const newtoken = await processRefresh();
        if (!newtoken) {
            throw new AuthenticationError();
        }
        return await postData(url, data);
    }
    return response;
};

export const queueLimit = async (
    backend: string | null,
    newLimit?: LimitUpdate,
): Promise<Limit> => {
    var res;
    const url = new URL(QUEUE_LIMIT_URL);
    if (backend) {
        url.searchParams.set('backend', backend);
    }
    if (newLimit !== undefined) {
        res = await postData(url.toString(), newLimit);
    } else {
        res = await getURL(url.toString());
    }
    return await res.json();
};

export const serviceActive = async (
    backend: string | null,
    newStatus: AvailabilityUpdate | undefined,
): Promise<Availability[]> => {
    var res;
    const url = new URL(SERVICE_ACTIVE_URL);
    if (backend) {
        url.searchParams.set('backend', backend);
    }
    if (newStatus !== undefined) {
        res = await postData(url.toString(), newStatus);
    } else {
        res = await getURL(url.toString());
    }
    const status = await res.json();
    return status;
};

export const fetchWaitTime = async (
    backend: string,
    skill: string,
): Promise<{ wait_time: number; active: boolean }> => {
    const url = new URL(STATUS_URL);
    backend && url.searchParams.set('backend', backend);
    skill && url.searchParams.set('skill', skill);
    const res = await fetch(url.toString());
    const { wait_time, active } = await res.json();
    return { wait_time, active };
};
