import {
    configureStore,
    getDefaultMiddleware,
    Action,
    DeepPartial,
} from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import * as storeJS from 'store';

import rootReducer, { RootState } from './reducers';
import eventEmitterMiddleware from 'network/middleware';
import hotJar from './middleware/hjMiddleware';
import googleAnalytics from 'middleware/gaMiddleware';
import sentryMiddleware from './middleware/sentryMiddleware';

export const history = createBrowserHistory();

let middleware = [
    routerMiddleware(history),
    eventEmitterMiddleware,
    googleAnalytics,
    sentryMiddleware,
    hotJar,
    ...getDefaultMiddleware(),
];

const createStore = (initialState?: DeepPartial<RootState>) => {
    const store = configureStore({
        reducer: rootReducer(history),
        middleware: middleware,
        preloadedState: initialState,
    });

    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('./reducers', () => {
            const newRootReducer = require('./reducers').default;
            store.replaceReducer(newRootReducer);
        });
    }

    // Update local storage with notification preference
    store.subscribe(() => {
        const notification = store.getState().app.notifications;
        if (notification) {
            storeJS.set('notifications', true);
        } else {
            storeJS.remove('notifications');
        }

        const audio = store.getState().app.audio;
        if (audio) {
            storeJS.set('audio', true);
        } else {
            storeJS.remove('audio');
        }
    });

    return store;
};

export type AppDispatch = ThunkDispatch<RootState, null, Action<string>>;

// Default dispatch to return void, but allow generic override of behaviour where required
export type AppThunk<T = Promise<void>> = ThunkAction<
    T,
    RootState,
    null,
    Action<string>
>;

export default createStore;
