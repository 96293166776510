import React from 'react';
import { connect } from 'react-redux';
import { incrementConnectionQueue } from 'reducers/volunteer';

import { Heading, Button } from './styled';

export const VolunteerDebug = ({ increaseQueue }) => {
    const handleConnectionClick = (e, amount) => {
        e.preventDefault();
        increaseQueue(amount);
    };
    return (
        <div>
            <Heading>Connection Queue:</Heading>
            <Button onClick={(e) => handleConnectionClick(e, 1)}>Add +1</Button>
            <Button onClick={(e) => handleConnectionClick(e, 10)}>
                Add +10
            </Button>
            <Button onClick={(e) => handleConnectionClick(e, 'clear')}>
                Clear
            </Button>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    increaseQueue: (amount) => dispatch(incrementConnectionQueue(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerDebug);
