import React from 'react';
import { connect } from 'react-redux';
import { SafeguardingBannerSetting } from 'chatConstants';
import { RootState } from 'reducers';
import SafeguardingBanner from '../components/SafeguardingBanner';

interface Props {
    settings: SafeguardingBannerSetting | null;
}

const SafeguardingBannerContainer: React.FC<Props> = ({ settings }) => {
    if (!settings) {
        return null;
    }
    const { title, text, link } = settings;
    if (!title && !text && !link) {
        return null;
    }
    return (
        <div>
            <SafeguardingBanner title={title} text={text} link={link} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings.safeguarding_banner,
});

export default connect(mapStateToProps)(SafeguardingBannerContainer);
