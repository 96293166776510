import FingerprintJSOS from '@fingerprintjs/fingerprintjs';
import FingerprintJSPro from '@fingerprintjs/fingerprintjs-pro';
import {
    FINGERPRINT_ENDPOINT,
    FINGERPRINT_PUBLIC_API_KEY,
    FINGERPRINT_SCRIPT_URL_PATTERN,
} from 'chatConstants';

import browserStore from 'store';

/**
 * Returns the user's current fingerprint
 *
 * If the fingerprint for this user is found in local storage, this function just returns
 * that. Otherwise, a fingerprint will be generated for them.
 *
 * How the fingerprint is generated is determined by a couple of factors: If the user has
 * consented to Functional Cookies, and if a Fingerprint JS Pro public key is set.
 *
 * If the user has consented to Functional Cookies via the OneTrust UI, and a Fingerprint
 * JS Pro public key is available, Fingerprint JS Pro will be used to generate a
 * fingerprint for the user.
 *
 * Otherwise, the open-source Fingerprint JS library is used.
 */
export async function getFingerprint() {
    let fingerprint: string = browserStore.get('fingerprint');

    if (fingerprint) {
        return fingerprint;
    }

    let fp = null;
    let result = null;

    if (FINGERPRINT_PUBLIC_API_KEY) {
        try {
            fp = await FingerprintJSPro.load({
                apiKey: FINGERPRINT_PUBLIC_API_KEY,
                // Specify EU region if endpoint is undefined. Otherwise use the custom endpoint.
                // NB: Only one of region or endpoint should be set at one time, otherwise
                // this will crash.
                region: FINGERPRINT_ENDPOINT ? undefined : 'eu',
                endpoint: FINGERPRINT_ENDPOINT || undefined,
                // Despite being an NPM package, the FPJS Pro runtime still
                // needs to be downloaded client-side. This tells the loader
                // where to download the script. (e.g. through the
                // webchat.samaritans.org Cloudflare worker proxy.)
                scriptUrlPattern: [
                    FINGERPRINT_SCRIPT_URL_PATTERN ||
                        FingerprintJSPro.defaultScriptUrlPattern,
                    FingerprintJSPro.defaultScriptUrlPattern,
                ],
            });

            result = await fp.get();
        } catch (e) {
            console.error(
                'FPJSPro encountered an error. Falling back to FPJSOS.',
            );
            fp = await FingerprintJSOS.load();
            result = await fp.get();
        }
    } else {
        fp = await FingerprintJSOS.load();
        result = await fp.get();
    }

    fingerprint = result.visitorId;

    browserStore.set('fingerprint', fingerprint);

    return fingerprint;
}

export async function clearFingerprint() {
    browserStore.remove('fingerprint');
}
