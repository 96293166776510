import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as UserAvatar } from 'assets/svgs/user-avatar.svg';
import { ReactComponent as UserAvatarFailed } from 'assets/svgs/user-avatar-failed.svg';
import StyledLocalMessage from './styled';

const LocalMessage = ({ children, modifier }) => (
    <StyledLocalMessage data-hj-suppress className={`${modifier && modifier}`}>
        {modifier === 'failed' ? <UserAvatarFailed /> : <UserAvatar />}
        {children}
    </StyledLocalMessage>
);

LocalMessage.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LocalMessage;
