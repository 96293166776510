import styled from 'styled-components';

const StyledMessage = styled.div`
    color: ${(props) => props.theme.colors.darkGrey};
    width: fit-content;
    word-wrap: break-word;
    max-width: 100%;
    border-radius: 10px;
    padding: 7px 14px;
    background-color: ${(props) => props.theme.colors.offWhite};
    font-size: 15px;
    position: relative;

    /* width: fit-content fallback for Edge */
    @supports (-ms-ime-align: auto) {
        display: table;
    }

    /* width: fit-content fallback for IE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: table;
    }

    /* Volunteer avatar */
    svg {
        position: absolute;
        left: -40px;
        top: 2px;
    }

    /* Speech bubble arrow */
    &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: ${(props) => props.theme.colors.offWhite};
        position: absolute;
        transform: rotate(45deg);
        left: -5px;
        top: 12px;
    }
`;

export default StyledMessage;
