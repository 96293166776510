import React from 'react';
import { connect } from 'react-redux';

import GeneralDebug from './GeneralDebug';
import ChatRoomDebug from './ChatRoomDebug';
import WaitingRoomDebug from './WaitingRoomDebug';
import VolunteerDebug from './VolunteerDebug';
import FeedbackRoomDebug from './FeedbackRoomDebug';
import Version from 'components/Version';
import { RootState } from 'reducers';

import { VOLUNTEER_URL } from 'chatConstants';

import {
    dropped,
    handleEstablishedConnection,
    ConnectionStatus,
    handleDroppedConnection,
} from 'reducers/connection';
import {
    Room,
    State,
    Status,
    setEnded,
    setErrored,
    setRoom,
    setBarred,
    setBusy,
    setOnline,
    setOffline,
    setQueueWait,
    startChat,
    volunteerLeave,
} from 'reducers/chat';
import { AppDispatch } from 'reduxStore';

const ROOMS = [Room.LANDING, Room.WAITING, Room.CHAT, Room.FEEDBACK];

type Props = {
    currentRoom: Room;
    isVolunteerMode: boolean;
    wait: number | null;
    ready: boolean;
    chat: RootState['chat'];
    connection: RootState['connection'];
    error: boolean;
    changeRoom: (room: Room) => void;
    waitTimes: (time: number) => void;
    readyToJoin: () => void;
    connectionStatus: (connected: boolean) => void;
    updateError: (staus: boolean) => void;
    updateServiceAvailability: (status: boolean) => void;
    serviceAvailable: boolean;
    updateChatEnd: () => void;
    updateReconnectAfterTimeout: () => void;
    updateUnknownReconnect: () => void;
    removeFromQueue: () => void;
    barCaller: (barred: boolean) => void;
    setBusy: (busy: boolean) => void;
};

const DebugContent = (props: Props) => {
    let { currentRoom, isVolunteerMode, wait, ready, chat, connection, error } =
        props;
    let { changeRoom, waitTimes, readyToJoin, connectionStatus, updateError } =
        props;
    let {
        updateServiceAvailability,
        serviceAvailable,
        updateChatEnd,
        updateReconnectAfterTimeout,
    } = props;
    let { updateUnknownReconnect, removeFromQueue, barCaller, setBusy } = props;

    const connected = connection.status === ConnectionStatus.ESTABLISHED;

    return (
        <form>
            {!isVolunteerMode && (
                <GeneralDebug
                    chat={chat}
                    connection={connection.status}
                    rooms={ROOMS}
                    changeRoom={changeRoom}
                    currentRoom={currentRoom}
                    serviceAvailable={serviceAvailable}
                    updateServiceAvailability={updateServiceAvailability}
                />
            )}

            {currentRoom === Room.WAITING && (
                <WaitingRoomDebug
                    wait={wait}
                    ready={ready}
                    waitTimes={waitTimes}
                    readyToJoin={readyToJoin}
                    connectionStatus={connectionStatus}
                    connected={connected}
                    removeFromQueue={removeFromQueue}
                    barCaller={barCaller}
                    updateError={updateError}
                    setBusy={setBusy}
                />
            )}

            {currentRoom === Room.CHAT && (
                <ChatRoomDebug
                    error={error}
                    connected={connected}
                    updateError={updateError}
                    connectionStatus={connectionStatus}
                    updateChatEnd={updateChatEnd}
                    updateReconnectAfterTimeout={updateReconnectAfterTimeout}
                    updateUnknownReconnect={updateUnknownReconnect}
                    ready={ready}
                    waitTimes={waitTimes}
                    readyToJoin={readyToJoin}
                />
            )}

            {currentRoom === Room.FEEDBACK && (
                <FeedbackRoomDebug
                    error={error}
                    connected={connected}
                    updateError={updateError}
                    connectionStatus={connectionStatus}
                />
            )}

            {isVolunteerMode && <VolunteerDebug />}
            <Version />
        </form>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentRoom: state.chat.room,
    isVolunteerMode: state.router.location.pathname.includes(VOLUNTEER_URL),
    ready: state.chat.state === State.STARTED,
    wait: state.chat.waitTime.queue,
    chat: state.chat,
    connection: state.connection,
    error: state.chat.status === Status.ERRORED,
    serviceAvailable: state.chat.status !== Status.OFFLINE,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeRoom: (room: Room) => dispatch(setRoom(room)),
    waitTimes: (wait: number) => dispatch(setQueueWait(wait)),
    readyToJoin: () => dispatch(startChat()),
    connectionStatus: (value: boolean) =>
        dispatch(value ? handleEstablishedConnection() : dropped()),
    updateError: (value: boolean) =>
        dispatch(value ? setErrored() : setOnline()),
    updateServiceAvailability: (value: boolean) =>
        dispatch(value ? setOnline() : setOffline()),
    updateChatEnd: () => {
        dispatch(setEnded());
        dispatch(volunteerLeave());
    },
    updateReconnectAfterTimeout: () =>
        dispatch(handleDroppedConnection({ code: 4002 } as CloseEvent)),
    updateUnknownReconnect: () =>
        dispatch(handleDroppedConnection({ code: 4001 } as CloseEvent)),
    removeFromQueue: () => dispatch(setEnded()),
    barCaller: (value: boolean) => {
        dispatch(value ? setBarred() : setOnline());
    },
    setBusy: (value: boolean) => {
        dispatch(value ? setBusy() : setOnline());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DebugContent);
