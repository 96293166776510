// Connection
export const CONNECTION_STATUS = 'CONNECTION_STATUS', // test
    // PAGE BEHAVIOURS
    PAGE_UNLOAD = 'PAGE_UNLOAD', // used but MR exists to remove
    // Chat Status
    LOADING = 'LOADING', // test
    CHANGE_ROOM = 'CHANGE_ROOM', // test
    RESET_CHAT = 'RESET_CHAT', // test
    EXIT_CHAT = 'EXIT_CHAT', // test
    SET_CHAT_END = 'SET_CHAT_END', // test
    // STATUS FROM SERVER
    STATUS_ERROR = 'ERROR', // test
    STATUS_START = 'START', // test
    STATUS_WAIT = 'WAIT', // test
    // APP
    SET_AUDIO = 'SET_AUDIO', // test
    SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'; // test
