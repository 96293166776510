import React from 'react';
import { connect } from 'react-redux';
import {
    Route,
    Redirect,
    RouteProps,
    RouteComponentProps,
} from 'react-router-dom';

import { getToken } from 'auth';
import { RootState } from 'reducers';

const isAuthenticated = () => (getToken() ? true : false);

interface Props extends RouteProps {
    loggedin: boolean;
}

function PrivateRoute({ render, loggedin, ...rest }: Props) {
    return (
        <Route
            {...rest}
            render={(routeProps: RouteComponentProps) =>
                loggedin && isAuthenticated() ? (
                    render && render(routeProps)
                ) : (
                    <Redirect
                        to={{
                            pathname: '/panel/login/',
                            state: { from: routeProps.location },
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    loggedin: state.admin.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
