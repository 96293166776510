import { push } from 'connected-react-router';
import { attemptConnection, closeConnection } from 'reducers/connection';
import { CHAT_URL } from 'chatConstants';
import { Room, exitChat } from 'reducers/chat';
import { AppThunk } from 'reduxStore';

export const backToVolunteerStart =
    (): AppThunk => async (dispatch, getState) => {
        const state = getState();
        const volunteer_url = state.router.location.pathname.replace(
            CHAT_URL,
            '',
        );
        dispatch(push(volunteer_url));
    };

export const joinVolunteerChat = (): AppThunk => async (dispatch) => {
    dispatch(attemptConnection());
    dispatch(push(CHAT_URL));
};

export const exitVolunteerChat = (): AppThunk => async (dispatch) => {
    dispatch(exitChat(Room.LANDING));
    dispatch(closeConnection());
    dispatch(backToVolunteerStart());
};
