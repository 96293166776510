import styled from 'styled-components';

const Label = styled.div`
    display: inline-block;
    position: fixed;
    padding: 1em;
    top: 80px;
    left: calc(100% - 4em);
    transform: rotate(-90deg);
    background-color: lightgray;
    background-color: #5d5d5d;
    color: white;
    font-weight: bold;
    z-index: 5;

    ${(props) => props.theme.hover} {
        cursor: pointer;
    }
`;

export const Sidebar = styled.div`
    padding: 20px;
    position: relative;
`;

export const Close = styled.div`
    position: absolute;
    right: 20px;
    font-weight: bold;
    font-size: 1.25rem;

    ${(props) => props.theme.hover} {
        cursor: pointer;
    }
`;

export const Panel = styled.div`
    margin-bottom: 20px;
    border-bottom: 1px solid #dadada;
    padding-bottom: 20px;
`;

export const Heading = styled.h3`
    margin-bottom: 10px;
`;

export const Room = styled.label`
    display: flex;
    margin-bottom: 5px;
    align-items: center;

    input {
        margin: 0 10px 0 0;
    }
`;

export const Button = styled.button`
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: rgb(0, 68, 85);
    border: none;
    color: white;
    font-size: 10pt;
    font-weight: bold;
`;

export const SliderContainer = styled.div`
    margin: 20px 0 20px 5px;
    max-width: 90%;
`;

export default Label;
