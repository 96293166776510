import React from 'react';

import LinkButton from 'components/LinkButton';
import WhiteRoomCoverPanel from 'components/RoomCoverPanels/White';

const BusyPanel = () => (
    <WhiteRoomCoverPanel>
        <h2>Unfortunately, we can't get to you on online chat right now</h2>
        <p>
            At the moment, we’re still growing the capacity for our online chat
            service and are not open round the clock. Right now, we don't think
            there are enough volunteers available to get to you before the
            service closes for the day.
        </p>
        <p>
            We know this might be frustrating. We hope you bear with us as we
            grow this service.
        </p>
        <p>
            You can call us for free, any time, day or night, on{' '}
            <a aria-label="Samaritans phone number 116 123" href="tel:116123">
                116 123
            </a>
            , or alternatively find another service.
        </p>
        <LinkButton href={process.env.REACT_APP_SERVICES_DESTINIATION}>
            Find another service
        </LinkButton>
    </WhiteRoomCoverPanel>
);

export default BusyPanel;
