import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SafeguardingBannerContainer from 'containers/SafeguardingBannerContainer';
import WaitTimePanel from 'components/WaitTimePanel';
import MainPanel from 'components/MainPanel';
import Room from 'components/Room';
import SidePanel from 'components/SidePanel';
import MobileMenu from 'components/MobileMenu';
import { StyledSidePanelInfo } from 'components/SidePanel/styled';
import Button from 'components/Button';
import Banner from 'components/Banner';
import Checkbox from 'components/Checkbox';

import { Label, Small } from 'components/Notifications/styled';

import {
    enableAudio,
    disableAudio,
    enableNotifications,
    disableNotifications,
} from 'reducers/app';
import { attemptJoinQueue, getWaitTime, State } from 'reducers/chat';
import { RootState } from 'reducers';
import { AppDispatch } from 'reduxStore';
import { useIsMobile } from 'utils/hooks';

type Props = {
    waitTime: number | null;
    updateWaitTime: () => void;
    joinAction: () => void;
    preparing: boolean;
    notifications: boolean;
    updateNotifications: (value: boolean) => void;
    audio: boolean;
    updateAudio: (value: boolean) => void;
};

export const LandingRoom = ({
    waitTime,
    preparing,
    updateWaitTime,
    joinAction,
    updateNotifications,
    updateAudio,
    notifications,
    audio,
}: Props) => {
    const isMobile = useIsMobile();

    useEffect(() => {
        if (waitTime === null && !preparing) {
            updateWaitTime();
        }
    }, [updateWaitTime, waitTime, preparing]);

    const [fpConsent, setFpConsent] = useState(false);
    const [joinClicked, setJoinClicked] = useState(false);

    const joinActionWrapper = () => {
        setJoinClicked(true);

        if (fpConsent) {
            joinAction();
        }
    };

    return (
        <>
            <Banner
                showNotifications={false}
                audio={audio}
                notifications={notifications}
                updateAudio={updateAudio}
                updateNotifications={updateNotifications}
            />
            {!isMobile && <SafeguardingBannerContainer />}
            <Room>
                <MainPanel>
                    <h1>Chat with us online</h1>
                    <p>
                        Sometimes typing is easier than talking. Our trained
                        volunteers will read your messages and respond in real
                        time, helping you work through what's on your mind. They
                        won't judge or tell you what to do, and you don't have
                        to be suicidal to reach out.
                    </p>

                    <h2>Enter the waiting room when you are ready</h2>

                    <p>
                        This service uses Browser Fingerprinting to help us
                        safeguard our callers and volunteers and prevent misuse.
                        It places a cookie on your device that can allow us to
                        see how your computer, smartphone or tablet has used
                        this service before.
                    </p>

                    <p>
                        You can read more about Browser Fingerprinting and how
                        we use this information in our{' '}
                        <a
                            href="https://www.samaritans.org/privacy-statement/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Statement
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://www.samaritans.org/privacy-statement/cookie-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Cookie Policy
                        </a>
                        .
                    </p>

                    <Checkbox
                        checked={fpConsent}
                        onChange={(event) => setFpConsent(event.target.checked)}
                        labelComponent={Label}
                        id="fingerprinting-consent"
                        ariaLabel="I agree to the use of Browser Fingerprinting"
                    >
                        I agree to the use of Browser Fingerprinting
                        <span className="asterisk">*</span>
                    </Checkbox>
                    {joinClicked && !fpConsent && (
                        <Small error>
                            You must agree to the use of Browser Fingerprinting
                            to use this service.
                        </Small>
                    )}
                    <Small>
                        If you do not consent to Browser Fingerprinting, you can
                        still access our services for free by phone on{' '}
                        <a href="tel:116123">116 123</a> or by emailing{' '}
                        <a href="mailto:jo@samaritans.org">jo@samaritans.org</a>
                    </Small>

                    <Button action={joinActionWrapper} dataIdentifier="join">
                        <span>Enter the waiting room</span>
                    </Button>
                </MainPanel>
                <SidePanel variants={['vCenter', 'textCenter']}>
                    <MobileMenu>
                        <h3>Other ways to get in touch</h3>
                        <p>
                            Call us:{' '}
                            <a
                                aria-label="Samaritans phone number 116 123"
                                href="tel:116123"
                            >
                                116 123
                            </a>
                        </p>
                        <p>
                            Email us:{' '}
                            <a href="mailto:jo@samaritans.org">
                                jo@samaritans.org
                            </a>
                        </p>
                    </MobileMenu>
                    <WaitTimePanel
                        waitTime={waitTime}
                        beforeTime="There's currently a"
                        paused
                    >
                        <StyledSidePanelInfo>
                            Estimated wait time
                        </StyledSidePanelInfo>
                    </WaitTimePanel>
                </SidePanel>
            </Room>
            {isMobile && <SafeguardingBannerContainer />}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    preparing: state.chat.state === State.PREPARING,
    waitTime: state.chat.waitTime.landing,
    notifications: state.app.notifications,
    audio: state.app.audio,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    updateWaitTime: () => dispatch(getWaitTime()),
    joinAction: () => dispatch(attemptJoinQueue()),
    updateNotifications: (value: boolean) =>
        dispatch(value ? enableNotifications() : disableNotifications()),
    updateAudio: (value: boolean) =>
        dispatch(value ? enableAudio() : disableAudio()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingRoom);
