import { AppThunk } from 'reduxStore';
import { handleMessage, MessageOrigin, MessageType } from 'reducers/messages';
import { handleStatus } from 'reducers/chat';
import { sendFeedback } from 'reducers/actions';

interface BaseReceivedMessage<T extends MessageType, V> {
    type: T;
    value: V;
    id: string;
    origin: MessageOrigin;
}

interface TextReceivedMessage
    extends BaseReceivedMessage<
        MessageType.MESSAGE | MessageType.ERROR,
        string
    > {}

interface UUIDReceivedMessage
    extends BaseReceivedMessage<
        MessageType.FAILED | MessageType.ACKNOWLEDGE | MessageType.JOIN,
        string
    > {}

interface BoolReceivedMessage
    extends BaseReceivedMessage<
        MessageType.TYPING | MessageType.START,
        boolean
    > {}

interface NumberReceivedMessage
    extends BaseReceivedMessage<MessageType.WAIT | MessageType.LEAVE, number> {}

export type ReceivedMessage =
    | TextReceivedMessage
    | BoolReceivedMessage
    | UUIDReceivedMessage
    | NumberReceivedMessage;

export const submitFeedback = (): AppThunk => async (dispatch, getState) => {
    let state = getState();
    dispatch(
        sendFeedback({
            pre: state.chat.data.preScore,
            post: state.chat.data.postScore,
        }),
    );
};

export const receiveMessage =
    (message: ReceivedMessage): AppThunk =>
    async (dispatch) => {
        dispatch(handleMessage(message));
        dispatch(handleStatus(message));
    };
