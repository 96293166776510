import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/Button';
import { getVolunteerQueue } from 'reducers/volunteer';
import { joinVolunteerChat } from 'actions/volunteer';
import RoomWithoutSidePanel from 'components/RoomWithoutSidePanel/styled';

export class VolunteerLandingPage extends React.Component {
    componentDidMount() {
        this.props.updateConnectionQueue();
        this.queuePoll = setInterval(this.props.updateConnectionQueue, 2000);
    }

    componentWillUnmount() {
        if (this.queuePoll) clearInterval(this.queuePoll);
    }

    pluralize(singular, plural, count) {
        if (count === 1) {
            return singular;
        } else {
            return plural;
        }
    }

    render() {
        const { joinChat, connectionQueue = 0 } = this.props;

        return (
            <RoomWithoutSidePanel textAlign="center">
                <h1>
                    There {this.pluralize('is', 'are', connectionQueue)}{' '}
                    {connectionQueue}{' '}
                    {this.pluralize('person', 'people', connectionQueue)} in the
                    queue.
                </h1>
                <Button action={joinChat} disabled={!connectionQueue}>
                    Start next chat
                </Button>
            </RoomWithoutSidePanel>
        );
    }
}

const mapStateToProps = (state) => ({
    connectionQueue: state.volunteer.queue,
});

const mapDispatchToProps = (dispatch) => ({
    joinChat: () => dispatch(joinVolunteerChat()),
    updateConnectionQueue: () => dispatch(getVolunteerQueue()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VolunteerLandingPage);
