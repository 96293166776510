import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

import { SENTRY_ENVIRONMENT } from 'chatConstants';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
    });
}

const REMOVED = '[REMOVED]';

const removeSensitiveData = (state) => {
    let returnedState = { ...state };
    returnedState.chat = {
        ...state.chat,
        preScore: REMOVED,
        postScore: REMOVED,
    };
    returnedState.messages = { ...state.messages };
    returnedState.messages.byId = Object.entries(
        returnedState.messages.byId,
    ).reduce((newObj, [key, message]) => {
        message = {
            ...message,
            value: REMOVED,
        };
        newObj[key] = message;
        return newObj;
    }, {});
    return returnedState;
};

const sentryMiddleware = createSentryMiddleware(Sentry, {
    getTags: (state) => ({
        chatID: state.chat.id,
        appVersion: process.env.REACT_APP_VERSION,
    }),
    stateTransformer: removeSensitiveData,
});

export default sentryMiddleware;
