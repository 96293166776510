import { createSelector } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'reduxStore';
import uuid4 from '../utils/uuid4';
import { RootState } from 'reducers';
import { State } from 'reducers/chat';

import * as networkAction from 'network/actions';
import { resetChat, sendMessage } from 'reducers/actions';

export enum MessageType {
    ACKNOWLEDGE = 'ACKNOWLEDGE',
    ERROR = 'ERROR',
    FAILED = 'FAILED',
    JOIN = 'JOIN',
    LEAVE = 'LEAVE',
    MESSAGE = 'MESSAGE',
    START = 'START',
    ENTER = 'ENTER',
    EXIT = 'EXIT',
    TYPING = 'TYPING',
    WAIT = 'WAIT',
    FEEDBACK = 'FEEDBACK',
    INACTIVE = 'INACTIVE',
}

export enum MessageOrigin {
    CLIENT = 'client',
    REMOTE = 'remote',
    SYSTEM = 'system',
}

export enum MessageStatus {
    UNSENT = 'unsent',
    CLIENT = 'client',
    SYSTEM = 'system',
    REMOTE = 'remote',
    FAILED = 'failed',
}

export interface Message {
    value: string | null | boolean | number;
    id: string;
    status: MessageStatus;
    timestamp: number;
    origin: MessageOrigin;
}

export const isMessage = (message: any): message is Message => {
    return (
        !!message &&
        (message as Message).value !== undefined &&
        (message as Message).id !== undefined &&
        (message as Message).status !== undefined &&
        (message as Message).timestamp !== undefined &&
        (message as Message).origin !== undefined
    );
};

interface MessageConstructor {
    value: string | null | boolean | number;
    id?: string;
    status?: MessageStatus;
    origin?: MessageOrigin;
}

interface makeMessage {
    (data: MessageConstructor): Message;
}
export const makeMessage: makeMessage = ({ value, id, status, origin }) => {
    return {
        id: id || uuid4(),
        timestamp: Date.now(),
        origin: origin || MessageOrigin.CLIENT,
        value,
        status: status || MessageStatus.CLIENT,
    };
};

interface initialState {
    id: Message;
    [id: string]: Message;
}
const initialState = {} as initialState;

const messages = createSlice({
    name: 'messages',
    initialState: initialState,
    reducers: {
        addMessage(state, action: PayloadAction<Message>) {
            // Avoid updating messages already in the system
            const id = action.payload.id;
            if (!(id in state)) {
                state[action.payload.id] = action.payload;
            }
        },
        receivedRemote(state, action: PayloadAction<string>) {
            if (action.payload in state) {
                state[action.payload].status = MessageStatus.REMOTE;
            }
        },
        receivedSystem(state, action: PayloadAction<string>) {
            if (action.payload in state) {
                state[action.payload].status = MessageStatus.SYSTEM;
            }
        },
        receivedLocal(state, action: PayloadAction<string>) {
            if (action.payload in state) {
                state[action.payload].status = MessageStatus.CLIENT;
            }
        },
        setFailed(state, action: PayloadAction<string>) {
            if (action.payload in state) {
                state[action.payload].status = MessageStatus.FAILED;
            }
        },
        clearMessages: () => initialState,
    },
    extraReducers: {
        [resetChat.toString()]: () => initialState,
    },
});

export const {
    addMessage,
    receivedLocal,
    receivedRemote,
    receivedSystem,
    setFailed,
    clearMessages,
} = messages.actions;

export default messages.reducer;

export const handleMessage =
    (message: networkAction.ReceivedMessage): AppThunk =>
    async (dispatch) => {
        if (message.type === MessageType.MESSAGE) {
            const processedMessage = makeMessage({
                id: message.id,
                value: message.value,
                origin: message.origin,
            } as MessageConstructor);
            dispatch(addMessage(processedMessage));
        } else if (message.type === MessageType.ACKNOWLEDGE) {
            if (message.origin === MessageOrigin.SYSTEM) {
                dispatch(receivedSystem(message.value));
            } else if (message.origin === MessageOrigin.REMOTE) {
                dispatch(receivedRemote(message.value));
            }
        } else if (message.type === MessageType.FAILED) {
            dispatch(setFailed(message.value));
        } else if (message.type === MessageType.START) {
            dispatch(sendUnsentMessages());
        }
    };

export const processNewMessage =
    (value: string): AppThunk =>
    async (dispatch, getState) => {
        let state = getState();

        let chatStarted = state.chat.state === State.STARTED;

        const message = makeMessage({
            value,
            status: chatStarted ? undefined : MessageStatus.UNSENT,
        } as MessageConstructor);

        dispatch(addMessage(message));

        // Send if connected
        if (chatStarted) {
            dispatch(sendMessage(message));
        }
    };

export const retryMessage =
    (messageID: string): AppThunk =>
    async (dispatch, getState) => {
        const message = getState().messages[messageID];
        //reset the state of the message to be local rather than failed
        dispatch(receivedLocal(messageID));
        dispatch(sendMessage(message));
    };

export const sendUnsentMessages =
    (): AppThunk => async (dispatch, getState) => {
        const messages = selectUnsentMessages(getState());
        messages.map((message) => dispatch(sendMessage(message)));
    };

export const selectMessages = (state: RootState) =>
    Object.values(state.messages);

export const selectOrderedMessages = createSelector(
    [selectMessages],
    (messages) => messages.sort((a, b) => a.timestamp - b.timestamp),
);

export const selectUnsentMessages = createSelector(
    [selectMessages],
    (messages) =>
        messages.filter((message) => message.status === MessageStatus.UNSENT),
);
