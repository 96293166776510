import React, { useEffect, ReactNode } from 'react';
import { connect } from 'react-redux';

import { setUrl, attemptReconnect } from 'reducers/connection';
import { Settings } from 'chatConstants';

import { setSkill, setBackend } from 'reducers/chat';
import { setSettings } from 'reducers/settings';
interface Props {
    url: string;
    setUrl: (newUrl: string) => void;
    backend: string;
    setBackend: (backend: string) => void;
    skill: string;
    setSkill: (skill: string) => void;
    setSettings: (value: Settings) => void;
    attemptReconnect: () => void;
    settings: Settings;
    children: ReactNode;
}
const ChatApp = ({
    url,
    backend,
    setBackend,
    skill,
    setSkill,
    setSettings,
    setUrl,
    attemptReconnect,
    settings,
    children,
}: Props) => {
    useEffect(() => {
        setUrl(url);
        attemptReconnect();
    }, [attemptReconnect, setUrl, url]);

    useEffect(() => {
        setSkill(skill);
    }, [skill, setSkill]);

    useEffect(() => {
        setBackend(backend);
    }, [backend, setBackend]);

    useEffect(() => {
        setSettings(settings);
    }, [setSettings, settings]);

    return <>{children}</>;
};

const mapDispatchToProps = {
    setSettings,
    setBackend,
    setSkill,
    setUrl,
    attemptReconnect,
};

export default connect(null, mapDispatchToProps)(ChatApp);
