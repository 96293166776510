import Push from 'push.js';
import appIcon from 'assets/img/app-icon.png';
import AudioNotification from 'assets/audio/notification.mp3';

Push.config({
    serviceWorker: './serviceWorker.js',
});

export const chatNotification = () => {
    Push.create('Your chat is ready', {
        body: 'Please click here to begin',
        requireInteraction: true,
        icon: appIcon,
        link: '',
        onClick: function () {
            window.focus();
            this.close();
        },
    });
};

export const audioNotification = new Audio(AudioNotification);
