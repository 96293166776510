import React, { Component } from 'react';
import Slider from 'rc-slider';
import Toggle from 'react-toggle';

import { Heading, Panel, SliderContainer } from './styled';

import 'rc-slider/assets/index.css';
import 'react-toggle/style.css';

class WaitingRoomDebug extends Component {
    render() {
        let {
            wait,
            ready,
            readyToJoin,
            waitTimes,
            connectionStatus,
            connected,
            removeFromQueue,
            barCaller,
            setBusy,
        } = this.props;
        let { updateError } = this.props;

        const maxWait = 3000;

        let handleWaittime = (value) => {
            // Dont update the waittime if value changed to max
            // This prevents a bug where the waittime from the API
            // is greater than the max and the onChange fires when
            // Slider tried to handle it
            if (value < maxWait) {
                waitTimes(value);
            }
        };

        let handleJoin = (event) => {
            readyToJoin(event.target.checked);
            let wait = 0;
            waitTimes(wait);
        };

        let handleConnected = (event) => connectionStatus(event.target.checked);
        let handleRemoveFromQueue = (event) =>
            removeFromQueue(event.target.checked);
        let handleBarCaller = (event) => barCaller(event.target.checked);
        let handleError = (event) => updateError(event.target.checked);
        let handleSetBusy = (event) => setBusy(event.target.checked);

        const marks = {
            0: '0',
            600: '10 mins',
            1200: '20 mins',
            1800: '30 mins',
            2400: '40 mins',
            3000: '50 mins',
        };

        return (
            <>
                <Panel>
                    <label>
                        <Heading>Wait time</Heading>
                        <SliderContainer>
                            <Slider
                                min={0}
                                max={maxWait}
                                value={wait}
                                marks={marks}
                                onChange={handleWaittime}
                            />
                        </SliderContainer>
                    </label>
                </Panel>
                <Panel>
                    <Heading>Ready to join</Heading>
                    <Toggle checked={ready} onChange={handleJoin} />
                    <Heading>Connection Status</Heading>
                    <Toggle
                        defaultChecked={!connected}
                        onChange={handleConnected}
                    />
                    <Heading>Removed from Queue</Heading>
                    <Toggle onChange={handleRemoveFromQueue} />
                    <Heading>Caller barred</Heading>
                    <Toggle onChange={handleBarCaller} />
                    <Heading>Server Error</Heading>
                    <Toggle onChange={handleError} />
                    <Heading>Busy</Heading>
                    <Toggle onChange={handleSetBusy} />
                </Panel>
            </>
        );
    }
}

export default WaitingRoomDebug;
