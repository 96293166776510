import styled from 'styled-components';

const StyledChatEndedPanel = styled.div`
    text-align: center;
    padding: 1rem;
    position: relative;
    font-size: 0.9rem;

    &::after {
        content: '';
        height: 1px;
        background-color: ${(props) => props.theme.colors.lightGrey};
        position: absolute;
        width: 95%;
        top: 0;
        left: 1rem;
    }

    svg {
        width: 48px;
        height: 48px;
        margin-bottom: 0.7rem;
        fill: ${(props) => props.theme.colors.lightPurple};
    }

    p {
        margin-bottom: 0.7rem;
    }

    a {
        color: ${(props) => props.theme.colors.body};
    }

    button {
        background-color: ${(props) => props.theme.colors.lightPurple};
        border-color: ${(props) => props.theme.colors.lightPurple};

        ${(props) => props.theme.hover} {
            color: ${(props) => props.theme.colors.lightPurple};
        }
    }
`;

export default StyledChatEndedPanel;
