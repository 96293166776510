import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import Toggle from 'react-toggle';

import { Panel, Heading } from './styled';

class ChatRoomDebug extends Component {
    render() {
        let { connected, error, updateError, connectionStatus, updateChatEnd } =
            this.props;
        let {
            updateReconnectAfterTimeout,
            updateUnknownReconnect,
            ready,
            waitTimes,
            readyToJoin,
        } = this.props;

        let handleConnected = (event) => {
            connectionStatus(event.target.checked);
        };

        let handleError = (event) => {
            updateError(event.target.checked);
        };

        let handleEndChat = (event) => {
            updateChatEnd(event.target.checked);
        };

        let handleReconnectAfterTimeout = (event) => {
            updateReconnectAfterTimeout(event.target.checked);
        };

        let handleUnknownReconnect = (event) => {
            updateUnknownReconnect(event.target.checked);
        };

        let handleJoin = (event) => {
            readyToJoin(event.target.checked);
            let wait = 0;
            waitTimes(wait);
        };

        let throwSentryError = () => {
            // Testing Sentry - REACT_APP_SENTRY_DSN and REACT_APP_SENTRY_ENVIRONMENT need to
            // be set in .env.development and the NODE_ENV changed in sentryMiddleware.js
            Sentry.captureException(new Error('Something broke'));
        };

        return (
            <Panel>
                <Heading>Connection Status</Heading>
                <Toggle defaultChecked={connected} onChange={handleConnected} />
                <Heading>Joined</Heading>
                <Toggle checked={ready} onChange={handleJoin} />
                <Heading>Server error</Heading>
                <Toggle defaultChecked={!!error} onChange={handleError} />
                <Heading>End chat</Heading>
                <Toggle onChange={handleEndChat} />
                <Heading>Reconnected after timeout</Heading>
                <Toggle onChange={handleReconnectAfterTimeout} />
                <Heading>Reconnected without ID</Heading>
                <Toggle onChange={handleUnknownReconnect} />
                <Heading>Throw Sentry error</Heading>
                <Toggle onChange={() => throwSentryError()} />
            </Panel>
        );
    }
}

export default ChatRoomDebug;
