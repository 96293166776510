import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';

import ChatRoom from '../components/ChatRoom';
import { exitVolunteerChat } from '../actions/volunteer';
import { ConnectionStatus } from 'reducers/connection';
import { RootState } from 'reducers';
import { State, Status } from 'reducers/chat';
import { selectOrderedMessages, retryMessage } from 'reducers/messages';

const mapStateToProps = (state: RootState) => ({
    connected: state.connection.status === ConnectionStatus.ESTABLISHED,
    serverError: state.chat.status === Status.ERRORED,
    ended: state.chat.state === State.ENDED,
    started: state.chat.state === State.STARTED,
    messages: selectOrderedMessages(state),
    typing: state.chat.typing,
});

const mapDispatchToProps = {
    handleExit: exitVolunteerChat,
    messageRetry: retryMessage,
};

const enhance = compose(
    withTheme,
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(ChatRoom);
