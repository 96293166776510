import { GTM_ID } from 'chatConstants';
import TagManager from 'react-gtm-module';
import store from 'store';

const tagManagerArgs = {
    gtmId: GTM_ID,
    dataLayerName: 'dataLayer',
};

const gaInit = () => {
    TagManager.initialize(tagManagerArgs);
};

export const gaDataLayer = (page, chatID, { ...args }) => {
    TagManager.dataLayer({
        dataLayer: {
            page,
            chatID,
            userid: store.get('fingerprint'),
            event: 'webchat',
            ...args,
        },
        dataLayerName: 'dataLayer',
    });
};

export const gaEvent = (event, { ...args }) => {
    TagManager.dataLayer({
        dataLayer: {
            event,
            userid: store.get('fingerprint'),
            ...args,
        },
        dataLayerName: 'dataLayer',
    });
};

export default gaInit;
