import React, { useRef, useEffect, ReactElement } from 'react';
import { Swipeable } from 'react-swipeable';

import TextMessage from 'components/TextMessage';
import TypingIndicator from 'components/TypingIndicator';

import StyledMessageList from './styled';
import { Message } from 'reducers/messages';

type Props = {
    connectionStartChat: ReactElement;
    setHasScrolled: (hasScrolled: boolean) => void;
    messages: Message[];
    showTyping: boolean;
    messageRetry: (messageID: string) => void;
};

const MessageList = ({
    connectionStartChat,
    setHasScrolled,
    messages,
    showTyping,
    messageRetry,
}: Props) => {
    const listEl = useRef<HTMLDivElement>(null);
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);

    useEffect(() => {
        if (listEl && listEl.current) {
            // scroll window on message send
            listEl.current.scrollTop = listEl.current.scrollHeight;
        }
    }, [messages, listEl]);

    // mouse scrolling
    const setScrollPosition = () => {
        // safari handles scrollTop differently to other browsers if column-reverse is set
        if (!listEl || !listEl.current) {
            return;
        }
        if (isSafari) {
            listEl.current.scrollTop >= 0
                ? setHasScrolled(false)
                : setHasScrolled(true);
        } else {
            listEl.current.offsetHeight + listEl.current.scrollTop >=
            listEl.current.scrollHeight
                ? setHasScrolled(false)
                : setHasScrolled(true);
        }
    };

    const handleSwipeDown = () => {
        setHasScrolled(true);
    };

    const handleSwipeUp = () => {
        if (listEl && listEl.current && listEl.current.scrollTop >= -5) {
            setHasScrolled(false);
        }
    };

    return (
        <StyledMessageList ref={listEl} onScroll={setScrollPosition}>
            <Swipeable
                onSwipedDown={handleSwipeDown}
                onSwipedUp={handleSwipeUp}
            >
                <ul aria-live="polite" aria-atomic="false">
                    {connectionStartChat}
                    {messages.map((message) => (
                        <li key={message.id}>
                            <TextMessage
                                text={message.value as string}
                                status={message.status}
                                origin={message.origin}
                                onRetry={() => messageRetry(message.id)}
                            />
                        </li>
                    ))}
                    {showTyping && (
                        <li key="typing">
                            <TypingIndicator />
                        </li>
                    )}
                </ul>
            </Swipeable>
        </StyledMessageList>
    );
};

export default MessageList;
