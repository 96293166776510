import React from 'react';
import { connect } from 'react-redux';

import ConnectionBar from '../components/ConnectionBar';
import { ConnectionStatus } from 'reducers/connection';

import { ReactComponent as VolunteerAvatar } from 'assets/svgs/volunteer-avatar.svg';
import { RootState } from 'reducers';
import { State, Status } from 'reducers/chat';

type Props = {
    connecting: boolean;
    connected: boolean;
    serverError: boolean;
    navigatorOnline: boolean;
};

const VolunteerConnectionBar = ({
    connecting,
    connected,
    serverError,
    navigatorOnline,
}: Props) => {
    let heading = (connecting ? `Connecting` : `Connected`) + ` with a Caller`;
    let description = `You're online`;
    let offline = !connected || serverError || !navigatorOnline;

    if (connecting) {
        description = `We're connecting you with a caller`;
    } else {
        if (!connected) {
            description = `We're having trouble reaching you`;
        } else if (serverError) {
            description = 'We are experiencing issues with our service';
        }
    }

    return (
        <ConnectionBar
            offline={offline}
            avatar={<VolunteerAvatar />}
            description={description}
            heading={heading}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    connecting:
        state.chat.state !== State.STARTED &&
        state.connection.status !== ConnectionStatus.ESTABLISHED,
    connected: state.connection.status === ConnectionStatus.ESTABLISHED,
    serverError: state.chat.status === Status.ERRORED,
});

export default connect(mapStateToProps)(VolunteerConnectionBar);
