export interface SafeguardingBannerSetting {
    title: string;
    text: string;
    link: string;
}

export interface Settings {
    safeguarding_banner: SafeguardingBannerSetting | null;
}

interface FrontendConfig {
    path: string;
    frontend: string;
    skill: string;
    websocket: string;
    backend: string;
    settings: Settings;
}
interface Config {
    chat_frontends: FrontendConfig[];
    api_domain: string;
    gtm_id: string;
    onetrust_id: string;
    use_debug: boolean;
    sentry_environment: string;
    fingerprint_public_api_key: string;
    fingerprint_endpoint: string;
    fingerprint_script_url_pattern: string;
}
const config: Config | undefined = (window as any).config;

// App
export const CHAT_FRONTENDS = config ? config['chat_frontends'] : [];
export const USE_DEBUG = config ? config['use_debug'] : false,
    // App Routes
    CHAT_URL = 'chat/',
    END_SESSION_URL = '/' + CHAT_URL + 'finish/',
    VOLUNTEER_URL = 'volunteer/',
    // API URLS
    IS_SECURE = process.env.REACT_APP_IS_SECURE === 'TRUE',
    BASE_DOMAIN =
        'http' +
        (IS_SECURE ? 's' : '') +
        '://' +
        (config ? config['api_domain'] : ''),
    CHAT_API_URL = BASE_DOMAIN + '/api',
    STATUS_URL = CHAT_API_URL + '/service-status/',
    PING_URL = CHAT_API_URL + '/detect-online/',
    QUEUE_LIMIT_URL = CHAT_API_URL + '/queue-limit/',
    SERVICE_ACTIVE_URL = CHAT_API_URL + '/service-active/',
    VOLUNTEER_API_URL = CHAT_API_URL + '/volunteer',
    QUEUE_LENGTH_URL = VOLUNTEER_API_URL + '/queue/',
    QUEUE_ADD_URL = VOLUNTEER_API_URL + '/add/',
    // WEBSOCKETS
    BASE_WEBSOCKET =
        'ws' +
        (IS_SECURE ? 's' : '') +
        '://' +
        (config ? config['api_domain'] : ''),
    VOLUNTEER_WEBSOCKET = BASE_WEBSOCKET + '/ws/chat/' + VOLUNTEER_URL,
    // Typing Timeouts
    STOPPED_TYPING_LIMIT = 2 * 1000,
    INACTIVITY_NOTIFICATION_LIMIT = 5 * 60 * 1000,
    // Retry
    RESEND_RETRY_LIMIT = 5,
    RECONNECT_TIMEOUT_LIMIT = 2 * 60 * 1000 + 15 * 1000, // Timout slightly beyond server wait time
    // Sentry
    SENTRY_ENVIRONMENT = config ? config['sentry_environment'] : '',
    // Google Analytics
    GTM_ID = config ? config['gtm_id'] : '',
    // OneTrust cookie control
    ONETRUST_ID = config ? config['onetrust_id'] : '',
    // FingerprintJS
    FINGERPRINT_PUBLIC_API_KEY = config
        ? config['fingerprint_public_api_key']
        : '',
    FINGERPRINT_ENDPOINT = config ? config['fingerprint_endpoint'] : '',
    FINGERPRINT_SCRIPT_URL_PATTERN = config ? config['fingerprint_script_url_pattern']: '';
