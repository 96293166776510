import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import VolunteerLandingPage from './../../pages/VolunteerLandingPage';
import VolunteerChatPage from './../../pages/VolunteerChatPage';
import DebugToolbar from '../../components/DebugToolbar';
import ErrorModal from './../../components/ErrorModal';
import { CHAT_URL } from 'chatConstants';

const VolunteerApp = ({ match }) => {
    return (
        <>
            <ErrorModal />
            <Switch>
                <Route
                    exact
                    path={match.path}
                    component={VolunteerLandingPage}
                />
                <Route
                    path={match.path + CHAT_URL}
                    component={VolunteerChatPage}
                />
                <Redirect to={match.path} />
            </Switch>
            <DebugToolbar />
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerApp);
